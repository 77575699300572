import { AppLogoImage } from '@components'
import { useEffect, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { getDocumentTitle } from '../../common/utils'
import { ConfirmStep, LoginStep, SelectCompanyStep } from '../../components/Login/steps'
import { resetLogin } from '../../redux/userSlice'

export const LoginScreen = (): JSX.Element => {
  const dispatch = useAppThunkDispatch()
  const step = useAppSelector(state => state.user.step)
  const email = useAppSelector(state => state.user.credentials.username)

  useEffect(() => {
    document.title = getDocumentTitle('Login')
  }, [])

  useEffect(() => {
    dispatch(resetLogin())
  }, [step])

  const stepConfig = useMemo(() => {
    switch (step) {
      case 'CONFIRM':
        return {
          title: 'Email Confirmation',
          component: <ConfirmStep />,
          description: (
            <span>
              We&apos;ve emailed <strong>{email}</strong>. Please use the provided link or enter the
              confirmation code manually
            </span>
          ),
        }
      case 'SELECT_COMPANY':
        return {
          title: 'Select Company',
          component: <SelectCompanyStep />,
        }
      case 'LOGIN':
      default:
        return {
          title: 'Log In/Sign Up',
          component: <LoginStep />,
          description: 'Please enter your email address',
        }
    }
  }, [step])

  return (
    <Container>
      <CoverImage alt='login banner' src='/login-cover.svg' />
      <FormContainer>
        <InnerWrap>
          <div className='flex flex-col items-center justify-center gap-4 mb-10'>
            <div className='grid justify-items-center mb-2'>
              <AppLogoImage className='h-24 hidden sm:block' variant='icon' />
              <AppLogoImage className='h-24 sm:hidden' />
            </div>
            <div className='ml-4 text-3xl font-semibold'>{stepConfig.title}</div>
            {stepConfig.description && (
              <div className='w-80 text-center'>{stepConfig.description}</div>
            )}
          </div>
          <div className='mx-2 md:mx-4 lg:mx-8'>{stepConfig.component}</div>
        </InnerWrap>
      </FormContainer>
    </Container>
  )
}

const Container = tw.div`
  block
  fixed md:!static
  h-screen
  md:flex
  top-0
  w-full
`

const CoverImage = tw.img`
  h-0
  md:h-full
  object-cover
  w-full
  md:w-3/5
`

const FormContainer = tw.div`
  flex
  h-full
  justify-center
  md:mt-0
  self-center
  w-full
  md:w-2/5
`

const InnerWrap = tw.div`
  flex
  flex-col
  justify-center
  mx-10
  lg:mx-32
  text-center
  w-full
  xl:w-4/5
  3xl:w-3/5
`
