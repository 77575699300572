import './DateInput.scss'
import 'react-datepicker/dist/react-datepicker.css'

import dayjs from 'dayjs'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import MaskedInput from 'react-text-mask'
import style from 'styled-components'
import tw from 'tailwind-styled-components'

type DateInputProps = {
  blue?: boolean
  className?: string
  error?: string
  label?: string
  labelClassName?: string
  range?: boolean
  required?: boolean
  value: Array<any> | any // Either an array of dates or a single date
  onChange: (value: Date | Array<Date>) => any
  size?: string
  showTimeSelect?: boolean
  placeholder?: string
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
  validator?: (value: string) => boolean | string | void
  maxDate?: any
  minDate?: any
  disabled?: boolean
  showAsterisk?: boolean
  showRequiredText?: boolean
}

type DatePickerProps = {
  isClearable?: boolean
  onChange: (value: any) => {}
  selected?: Date | null
  selectsRange?: boolean
  startDate?: Date | null
  endDate?: Date | null
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
}

// This component supports selecting either a single date or a date range
export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({
    className = '',
    error = '',
    label = '',
    labelClassName = '',
    range = false,
    required = false,
    disabled,
    value,
    onChange,
    showTimeSelect = false,
    placeholder = '',
    showMonthDropdown = false,
    showYearDropdown = false,
    maxDate = null,
    minDate = null,
    showAsterisk = true,
    showRequiredText,
  }: DateInputProps) => {
    const props: DatePickerProps = {
      isClearable: !disabled,
      onChange,
      selectsRange: range,
      showMonthDropdown,
      showYearDropdown,
    }

    const parseDate = (date?: string | Date) => {
      if (date && (typeof date === 'string' || date instanceof String)) return dayjs(date).toDate()
      return date as Date | undefined
    }
    if (range) {
      const [startDate, endDate] = value
      props['startDate'] = parseDate(startDate)
      props['endDate'] = parseDate(endDate)
    } else props['selected'] = parseDate(value)

    // Time select only works for single date input
    showTimeSelect = !range && showTimeSelect
    const dateFormat = `MM/dd/yyyy ${showTimeSelect ? 'h:mm aa' : ''}`

    return (
      <Container $disabled={!!disabled} className={className}>
        <div className='relative h-[52px]'>
          {label && (
            <LabelContainer>
              <Label>
                <span className={labelClassName}>{label}</span>&nbsp;
                {required && showAsterisk && (
                  <span className='text-error'>* {showRequiredText && 'required'}</span>
                )}
              </Label>
            </LabelContainer>
          )}
          <DatePicker
            {...props}
            dateFormat={dateFormat}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            placeholderText={placeholder}
            showTimeSelect={showTimeSelect}
            timeIntervals={15}
            customInput={
              <CustomInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />
            }
          />
          {!!error && <Error>{error}</Error>}
        </div>
      </Container>
    )
  },
)

const CustomInput = tw(MaskedInput)`
  appearance-none
  border
  border-gray-300
  rounded-lg
  w-full
  py-2
  px-3
  text-gray-700
  leading-tight
  focus:outline-none
  focus:border-brand-dark
  transition-all
  h-8
`

const Label = tw.label`
  font-poppins
  block
  text-dark-gray
  mb-0
  self-start
  mr-auto
  text-xs
`

const LabelContainer = tw.div`
  flex
  items-center
  mb-1
`

const Error = tw.div`
  text-error
  ml-1
  text-xs
  absolute
  -bottom-4
`

// when disabled, set equivalient to bg-gray-50
const Container = style.div<{ $disabled: boolean }>`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__close-icon::after {
    background-color: transparent !important;
    font-weight: 700;
    font-size: 24px;
    color: hsl(0, 0%, 80%);
  } 
  .react-datepicker__input-container {
    button {
      background-color: ${({ $disabled }) => ($disabled ? '#F6F8FC' : '')};
      cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      .react-datepicker {
        .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
          width: 20px;
          margin-top: 2px;
        }
        
        .react-datepicker__navigation-icon--previous, .react-datepicker__navigation-icon--next {
          &:before {
            border-width: 2px 2px 0 0;
          }
        }
        
        .react-datepicker__month-container {
          .react-datepicker__header {
            .react-datepicker__header__dropdown {
              display: flex;
              justify-content: space-between;
              padding: 0 20% 0 10%;
              margin-top: 8px;
              .react-datepicker__month-dropdown-container {
                font-family: 'Poppins';
                position: relative;
                .react-datepicker__month-read-view {
                  .react-datepicker__month-read-view--down-arrow {
                    right: -20px;
                    width: 6px;
                    height: 6px;
                    margin-top: 3px;
                    border-width: 2px 2px 0 0;
                  }
                }
                .react-datepicker__month-dropdown {
                  left: -10px;
                  width: 120px;
                  top: 0;
                  background-color: white;
                  border: 1px solid #ccc;
                  .react-datepicker__month-option {
                    font-family: 'Poppins';
                    font-size: 14px;
                    line-height: 20px;
                    color: #152437;
                    &:hover {
                      background-color: #F6F8FC;
                    }
                    .react-datepicker__month-option--selected {
                      left: 10px;
                    }
                    .react-datepicker__month-option--selected_month {
                      background-color: green !important;
                    }
                  }
                }
              }
              .react-datepicker__year-dropdown-container {
                font-family: 'Poppins';
                position: relative;
                .react-datepicker__year-read-view {
                  .react-datepicker__year-read-view--down-arrow {
                    right: -20px;
                    width: 6px;
                    height: 6px;
                    margin-top: 3px;
                    border-width: 2px 2px 0 0;
                  }
                }
                .react-datepicker__year-dropdown {
                  background-color: white;
                  left: unset;
                  right: -30px;
                  width: 80px;
                  top: 0;
                  border: 1px solid #ccc;
                  .react-datepicker__year-option {
                    font-family: 'Poppins';
                    font-size: 14px;
                    line-height: 20px;
                    color: #152437;
                    &:hover {
                      background-color: #F6F8FC;
                    }
                    .react-datepicker__year-option--selected {
                      left: 10px;
                    }
                    .react-datepicker__navigation--years-upcoming {
                      height: 20px;
                      margin-top: 0;
                      margin-bottom: 0;
                    }
                    .react-datepicker__navigation--years-previous {
                      height: 20px;
                      margin-bottom: 0;
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
