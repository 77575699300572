import {
  CatchError,
  cleanFilters,
  formatAxiosErrorToPayload,
  getErrorString,
  getObjectDifferences,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import {
  ContractLaneCarrier,
  ContractLaneDetails,
  ContractLaneDocument,
  ContractLaneListItem,
  NewContractLane,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  formatDateForBackend,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

export const initialNewContractLane = {
  equipmentType: '',
  origin: null,
  destination: null,
  customer: null,
  loadsPerWeek: '1',
  ratePerMile: '',
  minimumCharge: '',
  fuelCharge: '',
  commodity: '',
  allIn: '',
  weight: '',
  length: '',
  width: '',
  height: '',
  notes: '',
  startDate: null,
  endDate: null,
}

type ContractLaneState = {
  contractLane: Array<ContractLaneListItem>
  customerContractLanes: Array<ContractLaneListItem>
  newContractLane: NewContractLane
  contractLaneDetails: ContractLaneDetails
  backupContractLaneDetails: ContractLaneDetails
  loading: {
    contractLane: boolean
    contractLaneDetails: boolean
    addContractLane: boolean
    updateContractLane: boolean
    archiveContractLane: boolean
    contractLaneDocument: boolean
    addContractLaneCarrier: boolean
    getContractLaneCarriers: boolean
    deleteContractLaneCarrier: boolean
    updateContractLaneCarrier: boolean
    updateContractLaneCarrierOrder: boolean
    addContractLaneDocument: boolean
    deleteContractLaneDocument: boolean
  }
  count: number
  offset: number
  limit: number
  order: TableOrder
  filters: SearchFilters
  contractLaneDocuments: Array<ContractLaneDocument>
  contractLaneCarriers: ContractLaneCarrier[]
}

const initialState: ContractLaneState = {
  contractLane: [],
  customerContractLanes: [],
  newContractLane: initialNewContractLane,
  contractLaneDetails: {},
  backupContractLaneDetails: {},
  loading: {
    contractLane: false,
    contractLaneDetails: false,
    addContractLane: false,
    updateContractLane: false,
    archiveContractLane: false,
    contractLaneDocument: false,
    addContractLaneCarrier: false,
    getContractLaneCarriers: false,
    deleteContractLaneCarrier: false,
    updateContractLaneCarrier: false,
    updateContractLaneCarrierOrder: false,
    addContractLaneDocument: false,
    deleteContractLaneDocument: false,
  },
  count: 0,
  offset: 0,
  limit: 50,
  order: { label: '', direction: '', key: '' },
  filters: initialFilters,
  contractLaneDocuments: [],
  contractLaneCarriers: [],
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    equipment_type: filters.equipmentType,
    origin_city__icontains: filters.originCity,
    destination_city__icontains: filters.destinationCity,
    origin_state__in: filters.originState,
    destination_state__in: filters.destinationState,
  })

export const getContractLanes = createAsyncThunk(
  'contractLane/getContractLanes',
  async (_, { getState }) => {
    const {
      filters,
      limit = 50,
      offset = 0,
      order: { label, direction, key },
    } = (getState() as RootState).contractLane

    const ordering = getOrderingString(label, direction, key, '-id')

    const response = await api.get('/loads/api/list-create-contract-lane/', {
      params: {
        limit,
        offset,
        archived: false,
        ordering: ordering,
        ...getFilters(filters),
      },
    })

    return keysToCamelCase(response.data)
  },
)
export const addContractLane = createAsyncThunk(
  'contractLane/addContractLane',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { newContractLane: data } = (getState() as RootState).contractLane

    const payload = {
      equipmentType: data.equipmentType,
      origin: {
        city: data.origin?.city,
        state: data.origin?.state,
        country: data.origin?.country,
      },
      destination: {
        city: data.destination?.city,
        state: data.destination?.state,
        country: data.destination?.country,
      },
      loadsPerWeek: parseInt(data.loadsPerWeek as string),
      notes: data.notes,
      startDate: formatDateForBackend(data.startDate || ''),
      endDate: formatDateForBackend(data.endDate || ''),
    }

    try {
      const response = await api.post(
        '/loads/api/list-create-contract-lane/',
        keysToSnakeCase(payload),
      )
      dispatch(getContractLanes())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)
export const getContractLaneDetails = createAsyncThunk(
  'contractLane/getContractLaneDetails',
  async (id?: string | number) =>
    api.get(`/loads/api/contract-lane-rud/${id}/`).then(({ data }) => keysToCamelCase(data)),
)

export const archiveContractLane = createAsyncThunk(
  'contractLane/archiveContractLane',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/loads/api/contract-lane-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateContractLane = createAsyncThunk(
  'contractLane/updateContractLane',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { contractLaneDetails, backupContractLaneDetails } = (getState() as RootState)
      .contractLane

    const payload = getObjectDifferences(contractLaneDetails, backupContractLaneDetails)

    if (payload.startDate) payload.startDate = formatDateForBackend(payload.startDate)
    if (payload.endDate) payload.endDate = formatDateForBackend(payload.endDate)

    try {
      const response = await api.patch(
        `/loads/api/contract-lane-rud/${contractLaneDetails.id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getContractLaneDetails(contractLaneDetails.id as number))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getContractLaneDocuments = createAsyncThunk(
  'contractLane/getContractLaneDocuments',
  async (id: number | string) => {
    const response = await api.get(`/loads/api/list-create-contract-lane-document/${id}/`)
    return keysToCamelCase(response.data)
  },
)

export const addContractLaneDocument = createAsyncThunk(
  'contractLane/addContractLaneDocument',
  async (
    {
      document,
    }: {
      document: {
        file: File | null
        name?: string
      }
    },
    { getState, dispatch },
  ) => {
    const { contractLaneDetails: lane } = (getState() as RootState).contractLane

    const flData = new FormData()
    if (document.file) flData.append('file', document.file)
    flData.append('name', document.name || '')

    await api.post(`/loads/api/list-create-contract-lane-document/${lane.id}/`, flData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    dispatch(getContractLaneDocuments(lane?.id || ''))
  },
)

export const deleteContractLaneDocument = createAsyncThunk(
  'contractLane/deleteContractDocument',
  async (id: number, { dispatch }) => {
    await api.delete(`/loads/api/delete-contract-lane-document/${id}/`)
    dispatch(getContractLaneDocuments(id))
  },
)

export const getContractLaneCarriers = createAsyncThunk(
  'contractLane/getContractLaneCarriers',
  async (id: number | string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/list-create-contract-lane-carrier/${id}/`, {
        params: { limit: 100 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteContractLaneCarrier = createAsyncThunk(
  'contractLane/deleteContractLaneCarrier',
  async (id: number | string, { getState, dispatch, rejectWithValue }) => {
    const { contractLaneDetails } = (getState() as RootState).contractLane
    try {
      await api.delete(`/loads/api/contract-lane-carrier-ud/${id}/`)
      dispatch(getContractLaneCarriers(contractLaneDetails.id || 0))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addContractLaneCarrier = createAsyncThunk(
  'contractLane/addContractLaneCarrier',
  async (
    { carrierId, rate }: { carrierId: number; rate: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      contractLaneDetails: { id },
    } = (getState() as RootState).contractLane

    try {
      await api.post(`/loads/api/list-create-contract-lane-carrier/${id}/`, {
        carrier: carrierId,
        carrier_rate: rate,
      })
      dispatch(getContractLaneCarriers(id || 0))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateContractLaneCarrier = createAsyncThunk(
  'contractLane/updateContractLaneCarrier',
  async ({ id, rate }: { id: number; rate: string }, { getState, dispatch, rejectWithValue }) => {
    const { contractLaneDetails } = (getState() as RootState).contractLane

    try {
      await api.patch(`/loads/api/contract-lane-carrier-ud/${id}/`, {
        carrier: id,
        carrier_rate: rate,
      })
      dispatch(getContractLaneCarriers(contractLaneDetails.id || 0))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateContractLaneCarrierOrder = createAsyncThunk(
  'contractLane/updateContractLaneCarrierOrder',
  async (
    { id, direction }: { id: number; direction: 'UP' | 'DOWN' },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { contractLaneDetails } = (getState() as RootState).contractLane

    try {
      await api.post(`/loads/api/contract-lane-carrier-order/${id}/`, { direction })
      dispatch(getContractLaneCarriers(contractLaneDetails.id || 0))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const contractLaneSlice = createSlice({
  name: 'contractLane',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setNewContractLane(state, { payload }) {
      state.newContractLane = payload
    },
    setContractLaneDetails(state, { payload }) {
      state.contractLaneDetails = payload
    },
    resetCustomerContractLanes(state) {
      state.customerContractLanes = []
    },
    resetContractLaneCarriers(state) {
      state.contractLaneCarriers = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getContractLanes.pending, state => {
        state.loading.contractLane = true
      })
      .addCase(getContractLanes.fulfilled, (state, action) => {
        const { count, results, customerId } = action.payload
        if (customerId) state.customerContractLanes = results
        else {
          state.contractLane = results
          state.count = count
        }
        state.loading.contractLane = false
      })
      .addCase(getContractLanes.rejected, (state, { payload }) => {
        state.loading.contractLane = false
        toast.error(getErrorString(payload, 'Failed to get contract lanes'))
      })
      .addCase(addContractLane.pending, state => {
        state.loading.addContractLane = true
      })
      .addCase(addContractLane.fulfilled, state => {
        state.loading.addContractLane = false
        toast.success('Successfully created contract lane')
      })
      .addCase(addContractLane.rejected, (state, { payload }) => {
        state.loading.addContractLane = false
        toast.error(getErrorString(payload, 'Failed to create contract lane'))
      })
      .addCase(getContractLaneDetails.pending, state => {
        state.loading.contractLaneDetails = true
      })
      .addCase(getContractLaneDetails.fulfilled, (state, { payload }) => {
        state.contractLaneDetails = payload
        state.backupContractLaneDetails = payload
        state.loading.contractLaneDetails = false
      })
      .addCase(getContractLaneDetails.rejected, (state, { payload }) => {
        state.loading.contractLaneDetails = false
        toast.error(getErrorString(payload, 'Failed to get contract lane detail'))
      })
      .addCase(archiveContractLane.pending, state => {
        state.loading.archiveContractLane = true
      })
      .addCase(archiveContractLane.fulfilled, state => {
        state.loading.archiveContractLane = false
        toast.success('Successfully archived contract lane')
      })
      .addCase(archiveContractLane.rejected, (state, { payload }) => {
        state.loading.archiveContractLane = false
        toast.error(getErrorString(payload, 'Failed to archived contract lane'))
      })
      .addCase(updateContractLane.pending, state => {
        state.loading.updateContractLane = true
      })
      .addCase(updateContractLane.fulfilled, state => {
        state.loading.updateContractLane = false
        toast.success('Successfully updated contract lane')
      })
      .addCase(updateContractLane.rejected, (state, { payload }) => {
        state.loading.updateContractLane = false
        toast.error(getErrorString(payload, 'Failed to update contract lane'))
      })
      .addCase(getContractLaneDocuments.pending, state => {
        state.loading.contractLaneDocument = true
      })
      .addCase(getContractLaneDocuments.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.contractLaneDocuments = results
        state.count = count
        state.loading.contractLaneDocument = false
      })
      .addCase(getContractLaneDocuments.rejected, (state, { payload }) => {
        state.loading.contractLaneDocument = false
        toast.error(getErrorString(payload, 'Failed to get contract lane documents'))
      })
      .addCase(addContractLaneDocument.pending, state => {
        state.loading.addContractLaneDocument = true
      })
      .addCase(addContractLaneDocument.fulfilled, state => {
        state.loading.addContractLaneDocument = false
        toast.success('Successfully added document')
      })
      .addCase(addContractLaneDocument.rejected, (state, { payload }) => {
        state.loading.addContractLaneDocument = false
        toast.error(getErrorString(payload, 'Error adding document'))
      })
      .addCase(deleteContractLaneDocument.pending, state => {
        state.loading.deleteContractLaneDocument = true
      })
      .addCase(deleteContractLaneDocument.fulfilled, state => {
        state.loading.deleteContractLaneDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteContractLaneDocument.rejected, (state, { payload }) => {
        state.loading.deleteContractLaneDocument = false
        toast.error(getErrorString(payload, 'Error deleting document'))
      })
      .addCase(addContractLaneCarrier.pending, state => {
        state.loading.addContractLaneCarrier = true
      })
      .addCase(addContractLaneCarrier.fulfilled, state => {
        state.loading.addContractLaneCarrier = false
        toast.success('Successfully added carrier')
      })
      .addCase(addContractLaneCarrier.rejected, (state, { payload }) => {
        state.loading.addContractLaneCarrier = false
        toast.error(getErrorString(payload, 'Failed to add carrier'))
      })
      .addCase(getContractLaneCarriers.pending, state => {
        state.loading.getContractLaneCarriers = true
      })
      .addCase(getContractLaneCarriers.fulfilled, (state, { payload }) => {
        state.loading.getContractLaneCarriers = false
        state.contractLaneCarriers = payload.results
      })
      .addCase(getContractLaneCarriers.rejected, (state, { payload }) => {
        state.loading.getContractLaneCarriers = false
        toast.error(getErrorString(payload, 'Failed to get carriers'))
      })
      .addCase(deleteContractLaneCarrier.pending, state => {
        state.loading.deleteContractLaneCarrier = true
      })
      .addCase(deleteContractLaneCarrier.fulfilled, state => {
        state.loading.deleteContractLaneCarrier = false
        toast.success('Successfully deleted carrier')
      })
      .addCase(deleteContractLaneCarrier.rejected, (state, { payload }) => {
        state.loading.deleteContractLaneCarrier = false
        toast.error(getErrorString(payload, 'Failed to delete carrier'))
      })
      .addCase(updateContractLaneCarrier.pending, state => {
        state.loading.updateContractLaneCarrier = true
      })
      .addCase(updateContractLaneCarrier.fulfilled, state => {
        state.loading.updateContractLaneCarrier = false
        toast.success('Successfully updated carrier')
      })
      .addCase(updateContractLaneCarrier.rejected, (state, { payload }) => {
        state.loading.updateContractLaneCarrier = false
        toast.error(getErrorString(payload, 'Failed to update carrier'))
      })
      .addCase(updateContractLaneCarrierOrder.pending, state => {
        state.loading.updateContractLaneCarrierOrder = true
      })
      .addCase(updateContractLaneCarrierOrder.fulfilled, state => {
        state.loading.updateContractLaneCarrierOrder = false
        toast.success('Successfully updated carrier order')
      })
      .addCase(updateContractLaneCarrierOrder.rejected, (state, { payload }) => {
        state.loading.updateContractLaneCarrierOrder = false
        toast.error(getErrorString(payload, 'Failed to update carrier order'))
      })
  },
})

export const {
  setLimit,
  setOffset,
  setFilters,
  setOrder,
  setNewContractLane,
  setContractLaneDetails,
  resetCustomerContractLanes,
  resetContractLaneCarriers,
} = contractLaneSlice.actions

export default contractLaneSlice.reducer
