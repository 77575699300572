import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import { Carrier, SearchFilters, ShipperCarrier } from '../common/types'
import { keysToCamelCase, keysToSnakeCase, mapLoadStatus } from '../common/utils'

export enum CarrierApprovedToHaulStatus {
  CARRIER_INVITED = 'CARRIER_INVITED',
  CARRIER_ACTIVE = 'CARRIER_ACTIVE',
  CARRIER_INACTIVE = 'CARRIER_INACTIVE',
}

export type NewCarrierContact = {
  id: number
  name: string
  email: string
  phone: string
  notes: string
  isPrimary?: boolean
}

export type ShipperCarrierContact = {
  id: number
  name: string
  email: string
  phone: string
  notes: string
  createdAt?: string
  carrierCompanyId?: number
  isPrimary?: boolean
  isPrimaryCarrierContact?: boolean
  isPrimaryShipperContact?: boolean
}

type CarriersState = {
  loading: {
    carriers: boolean
    carrier: boolean
    carrierPreviousLoads: boolean
    notes: boolean
    addNote: boolean
    deleteNote: boolean
    carrierContacts: boolean
    invitingCarrier: boolean
    resendInviteCarrier: boolean
    updateCarrierApprovedToHaulStatus: boolean
    addCarrierContact: boolean
    deleteCarrierContact: boolean
    updateCarrierContact: boolean
  }
  count: {
    carriers: number
    carrierPreviousLoads: number
    notes: number
    carrierContacts: number
    shipperCarrierContacts: number
  }
  carriers: Carrier[]
  offset: number
  limit: number
  carrier: {
    id: number
    name: string
    mcNumber: string
    dotNumber: string
    address: {
      street: string
      city: string
      state: string
    }
    contacts: {
      email: string
      isDispatch: boolean
      isPrimary: boolean
      name: string
      phone: string
    }
    phone: string
    shipments: number
    price: number
    status: CarrierApprovedToHaulStatus
    statusBackup: CarrierApprovedToHaulStatus
  }
  carrierPreviousLoads: Array<any>
  notes: Array<any>
  carrierContacts: Array<ShipperCarrierContact>
  isInviteCarrierModalVisible: boolean
  shipperCarriers: ShipperCarrier[]
  filters: SearchFilters
}

type CarrierResendInvite = {
  id_type: string
  id_number?: string
}

type CarrierInvite = CarrierResendInvite & {
  name: string
  email: string
}

const initialState: CarriersState = {
  loading: {
    carriers: false,
    carrier: false,
    carrierPreviousLoads: false,
    notes: false,
    addNote: false,
    deleteNote: false,
    carrierContacts: false,
    invitingCarrier: false,
    resendInviteCarrier: false,
    updateCarrierApprovedToHaulStatus: false,
    addCarrierContact: false,
    deleteCarrierContact: false,
    updateCarrierContact: false,
  },
  count: {
    carriers: 0,
    carrierPreviousLoads: 0,
    notes: 0,
    carrierContacts: 0,
    shipperCarrierContacts: 0,
  },
  carriers: [],
  offset: 0,
  limit: 50,
  carrier: {
    id: -1,
    name: '',
    mcNumber: '',
    dotNumber: '',
    address: {
      street: '',
      city: '',
      state: '',
    },
    contacts: {
      email: '',
      isDispatch: false,
      isPrimary: false,
      name: '',
      phone: '',
    },
    phone: '',
    shipments: 0,
    price: 0,
    status: CarrierApprovedToHaulStatus.CARRIER_INACTIVE,
    statusBackup: CarrierApprovedToHaulStatus.CARRIER_INACTIVE,
  },
  carrierPreviousLoads: [],
  notes: [],
  carrierContacts: [],
  isInviteCarrierModalVisible: false,
  shipperCarriers: [],
  filters: initialFilters,
}

export const getCarriers = createAsyncThunk(
  'carriers/getCarriers',
  async ({ carriersLimit = 100 }: { carriersLimit?: number }, { getState, rejectWithValue }) => {
    const { limit = 10, offset = 0, filters } = (getState() as RootState).carriers
    try {
      const response = await api.get('/customer/api/customer-managed-carrier-list/', {
        params: {
          limit: carriersLimit ?? limit,
          name__icontains: filters.name || null,
          mc_number: filters.mcNumber || null,
          dot_number: filters.dotNumber || null,
          offset,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getShipperCarriers = createAsyncThunk(
  'carriers/getShipperCarriers',
  async (
    payload: { search?: string; carriersLimit?: number } | undefined,
    { getState, rejectWithValue },
  ) => {
    const { search = '', carriersLimit = 100 } = payload || {}
    const { limit = 10, offset = 0 } = (getState() as RootState).carriers
    try {
      const response = await api.get('/customer/api/shipper-carriers/', {
        params: {
          limit: carriersLimit ?? limit,
          offset,
          search,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const inviteCarrier = createAsyncThunk(
  'carriers/inviteCarrier',
  async (payload: CarrierInvite, { rejectWithValue }) => {
    try {
      const response = await api.post('/customer/api/invite-carrier/', payload)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const resendInviteCarrier = createAsyncThunk(
  'carriers/resendInviteCarrier',
  async (payload: CarrierResendInvite, { rejectWithValue }) => {
    try {
      const response = await api.post('/customer/api/resend-invite-carrier/', payload)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierDetails = createAsyncThunk(
  'carriers/getCarrierDetails',
  async (id: string | number | undefined, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/carrier/${id}/`)
      return keysToCamelCase({ ...response.data, id })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierPreviousLoads = createAsyncThunk(
  'carriers/getCarrierPreviousLoads',
  async ({ id, limit }: { id: string; limit: number }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/customer-previous-loads/${id}/`, {
        params: { limit },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierNotes = createAsyncThunk(
  'carriers/getCarrierNotes',
  async ({ id, limit }: { id: string; limit: number }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/customer/api/carrier/carrier-note-customer-list-create/${id}/`,
        {
          params: { limit },
        },
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addNote = createAsyncThunk(
  'carriers/addNote',
  async (
    { id, note, limit }: { id: string; note: any; limit: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `/customer/api/carrier/carrier-note-customer-list-create/${id}/`,
        { note },
      )
      dispatch(getCarrierNotes({ id, limit }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteNote = createAsyncThunk(
  'carriers/deleteNote',
  async (
    { noteId, carrierId, limit }: { noteId: number | string; carrierId: string; limit: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.delete(
        `/customer/api/carrier/carrier-customer-note-rud/${noteId}/`,
      )
      dispatch(getCarrierNotes({ id: carrierId, limit }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierContacts = createAsyncThunk(
  'carriers/getCarrierContacts',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/list-create-shipper-carrier-contact/${id}/`, {
        params: { limit: 30 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addCarrierContact = createAsyncThunk(
  'carriers/addCarrierContact',
  async (payload: NewCarrierContact, { getState, dispatch, rejectWithValue }) => {
    const {
      carrier: { id },
    } = (getState() as RootState).carriers
    try {
      const response = await api.post(
        `/shipper/api/list-create-shipper-carrier-contact/${id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getCarrierContacts(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCarrierApprovedToHaulStatus = createAsyncThunk(
  'carriers/updateCarrierApprovedToHaulStatus',
  async (
    { id, status }: { id: string | number; status: CarrierApprovedToHaulStatus },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.patch(`/customer/api/carrier/${id}/`, {
        status,
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCarrierContact = createAsyncThunk(
  'carriers/updateCarrierContact',
  async (payload: ShipperCarrierContact, { getState, dispatch, rejectWithValue }) => {
    const {
      carrier: { id },
    } = (getState() as RootState).carriers

    try {
      const response = await api.patch(
        `shipper/api/shipper-carrier-contact-ud/${payload.id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getCarrierContacts(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteCarrierContact = createAsyncThunk(
  'carriers/deleteCarrierContact',
  async (contactId: number, { getState, dispatch, rejectWithValue }) => {
    const {
      carrier: { id },
    } = (getState() as RootState).carriers

    try {
      const response = await api.delete(`shipper/api/shipper-carrier-contact-ud/${contactId}/`)
      dispatch(getCarrierContacts(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setInviteCarrierModalVisible(state, { payload }) {
      state.isInviteCarrierModalVisible = payload.isVisible
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarriers.pending, state => {
        state.loading.carriers = true
      })
      .addCase(getCarriers.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.carriers = false
        state.count.carriers = count
        state.carriers = results.map((carrier: any) => ({
          ...carrier,
          location: carrier.baseLocation,
        }))
      })
      .addCase(getCarriers.rejected, (state, { payload }) => {
        state.loading.carriers = false
        toast.error(getErrorString(payload, 'Error getting carriers'))
      })
      .addCase(getShipperCarriers.pending, state => {
        state.loading.carriers = true
      })
      .addCase(getShipperCarriers.fulfilled, (state, { payload }) => {
        state.loading.carriers = false
        state.shipperCarriers = payload.results.map((carrier: ShipperCarrier) => ({
          ...carrier,
          contact: carrier.contacts,
          carrierId: carrier.carrierCompanyId,
          name: carrier.carrierCompanyName,
        }))
      })
      .addCase(getShipperCarriers.rejected, (state, { payload }) => {
        state.loading.carriers = false
        toast.error(getErrorString(payload, 'Error getting carriers'))
      })
      .addCase(inviteCarrier.pending, state => {
        state.loading.invitingCarrier = true
      })
      .addCase(inviteCarrier.fulfilled, state => {
        state.loading.invitingCarrier = false
        toast.success('Carrier successfully invited')
        state.isInviteCarrierModalVisible = false
      })
      .addCase(inviteCarrier.rejected, (state, { payload }) => {
        state.loading.invitingCarrier = false
        toast.error(
          getErrorString(payload, 'Error inviting carrier, please try again or contact support.'),
        )
      })
      .addCase(resendInviteCarrier.pending, state => {
        state.loading.resendInviteCarrier = true
      })
      .addCase(resendInviteCarrier.fulfilled, state => {
        state.loading.resendInviteCarrier = false
        toast.success('Carrier invite successfully resent')
      })
      .addCase(resendInviteCarrier.rejected, (state, { payload }) => {
        state.loading.resendInviteCarrier = false
        toast.error(
          getErrorString(
            payload,
            'Error re-sending carrier invite, please try again or contact support.',
          ),
        )
      })
      .addCase(getCarrierDetails.pending, state => {
        state.loading.carrier = true
      })
      .addCase(getCarrierDetails.fulfilled, (state, { payload: carrier }) => {
        state.loading.carrier = false
        state.carrier = {
          ...carrier,
          contacts: {
            email: carrier.carrierCompanyContacts.email,
            isDispatch: carrier.carrierCompanyContacts.isDispatch,
            isPrimary: carrier.carrierCompanyContacts.isPrimary,
            name: carrier.carrierCompanyContacts.name,
            phone: carrier.carrierCompanyContacts.phone,
          },
          address: {
            street: carrier.address,
            city: carrier.city,
            state: carrier.stateProvinceRegion,
          },
        }
      })
      .addCase(getCarrierDetails.rejected, (state, { payload }) => {
        state.loading.carrier = false
        toast.error(getErrorString(payload, 'Error getting carrier details'))
      })
      .addCase(getCarrierPreviousLoads.pending, state => {
        state.loading.carrierPreviousLoads = true
      })
      .addCase(getCarrierPreviousLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.carrierPreviousLoads = false
        state.count.carrierPreviousLoads = count
        state.carrierPreviousLoads = results.map((load: any) => ({
          ...load,
          newLoadStatusDisplay: mapLoadStatus(load.newLoadStatusDisplay),
        }))
      })
      .addCase(getCarrierPreviousLoads.rejected, (state, { payload }) => {
        state.loading.carrierPreviousLoads = false
        toast.error(getErrorString(payload, 'Error getting previous loads'))
      })
      .addCase(getCarrierNotes.pending, state => {
        state.loading.notes = true
      })
      .addCase(getCarrierNotes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.notes = false
        state.count.notes = count
        state.notes = results.map((note: any) => ({
          ...note,
          sender: note.createdByDisplay,
          date: note.createdAt,
          text: note.note,
        }))
      })
      .addCase(getCarrierNotes.rejected, (state, { payload }) => {
        state.loading.notes = false
        toast.error(getErrorString(payload, 'Error getting notes'))
      })
      .addCase(addNote.pending, state => {
        state.loading.addNote = true
      })
      .addCase(addNote.fulfilled, state => {
        state.loading.addNote = false
        toast.success('Successfully added note')
      })
      .addCase(addNote.rejected, (state, { payload }) => {
        state.loading.addNote = false
        toast.error(getErrorString(payload, 'Error adding note'))
      })
      .addCase(deleteNote.pending, state => {
        state.loading.deleteNote = true
      })
      .addCase(deleteNote.fulfilled, state => {
        state.loading.deleteNote = false
        toast.success('Successfully deleted note')
      })
      .addCase(deleteNote.rejected, (state, { payload }) => {
        state.loading.deleteNote = false
        toast.error(getErrorString(payload, 'Error deleting note'))
      })
      .addCase(getCarrierContacts.pending, state => {
        state.loading.carrierContacts = true
      })
      .addCase(getCarrierContacts.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.carrierContacts = false
        state.count.carrierContacts = count
        state.carrierContacts = results
      })
      .addCase(getCarrierContacts.rejected, (state, { payload }) => {
        state.loading.carrierContacts = false
        toast.error(getErrorString(payload, 'Error getting carrier contacts'))
      })
      .addCase(updateCarrierApprovedToHaulStatus.pending, (state, action) => {
        state.carrier.statusBackup = state.carrier.status
        state.carrier.status = action.meta.arg.status
        state.loading.updateCarrierApprovedToHaulStatus = true
      })
      .addCase(updateCarrierApprovedToHaulStatus.fulfilled, state => {
        state.loading.updateCarrierApprovedToHaulStatus = false
        toast.success('Carrier status updated successfully.')
      })
      .addCase(updateCarrierApprovedToHaulStatus.rejected, (state, { payload }) => {
        state.carrier.status = state.carrier.statusBackup
        state.loading.updateCarrierApprovedToHaulStatus = false
        toast.error(getErrorString(payload, 'Failed to update carrier status'))
      })
      .addCase(updateCarrierContact.pending, state => {
        state.loading.updateCarrierContact = true
      })
      .addCase(updateCarrierContact.fulfilled, state => {
        state.loading.updateCarrierContact = false
        toast.success('Succssfully updated carrier contact')
      })
      .addCase(updateCarrierContact.rejected, (state, { payload }) => {
        state.loading.updateCarrierContact = false
        toast.error(getErrorString(payload, 'Failed to update carrier contact'))
      })
      .addCase(deleteCarrierContact.pending, state => {
        state.loading.deleteCarrierContact = true
      })
      .addCase(deleteCarrierContact.fulfilled, state => {
        state.loading.deleteCarrierContact = false
        toast.success('Successfully deleted carrier contact')
      })
      .addCase(deleteCarrierContact.rejected, (state, { payload }) => {
        state.loading.deleteCarrierContact = false
        toast.error(getErrorString(payload, 'Failed to delete carrier contact'))
      })
      .addCase(addCarrierContact.pending, state => {
        state.loading.addCarrierContact = true
      })
      .addCase(addCarrierContact.fulfilled, state => {
        state.loading.addCarrierContact = false
        toast.success('Successfully added carrier contact')
      })
      .addCase(addCarrierContact.rejected, (state, { payload }) => {
        state.loading.addCarrierContact = false
        toast.error(getErrorString(payload, 'Failed to create carrier contact'))
      })
  },
})

export const { setLimit, setOffset, setInviteCarrierModalVisible, setFilters } =
  carriersSlice.actions

export default carriersSlice.reducer
