import tw from 'tailwind-styled-components'

import { Button } from '../button'
import { Modal } from '../modal'
import { useConfirmDialogStateSingleton } from './useConfirmDialog'

export type ConfirmDialogProps = {
  isVisible: boolean
  setVisible: (isVisible: boolean) => void
  text?: string
  message?: string
  actionLabel?: string
  cancelLabel?: string
  onConfirm: () => Promise<any> | any
  loading?: boolean
  type?: 'danger' | 'success'
  showConfirm?: boolean
}

export const ConfirmDialog = ({
  isVisible,
  setVisible,
  text,
  message,
  actionLabel = 'Delete',
  cancelLabel = 'Cancel',
  onConfirm,
  loading,
  type = 'danger',
  showConfirm = true,
}: ConfirmDialogProps) => {
  const handleConfirm = async () => {
    try {
      await onConfirm()
      setVisible(false)
    } catch (e) {
      // Swallow error
    }
  }

  const computedMessage = message || `Are you sure you want to delete ${text}?`

  return (
    <Modal
      className='w-[400px]'
      isVisible={isVisible}
      setVisible={setVisible}
      showCloseIcon={false}
    >
      <div>
        <div className='text-center'>
          <Title>{computedMessage}</Title>
          <div className='flex p-4 justify-between'>
            <Button
              fullWidth
              short
              sm
              className='mr-4 w-full'
              type='secondary'
              onClick={() => setVisible(false)}
            >
              {cancelLabel}
            </Button>

            {showConfirm && (
              <Button
                fullWidth
                sm
                className='w-full'
                loading={loading}
                type={type}
                onClick={handleConfirm}
              >
                {actionLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const Title = tw.div`
  p-6
  text-xl
  border-b
  border-border-gray
`

export const TopLevelConfirmDialog = () => {
  const {
    isVisible,
    setVisible,
    onConfirm,
    message,
    actionLabel,
    cancelLabel,
    loading,
    type,
    showConfirm,
  } = useConfirmDialogStateSingleton()

  return (
    <ConfirmDialog
      actionLabel={actionLabel}
      cancelLabel={cancelLabel}
      isVisible={isVisible}
      loading={loading}
      message={message}
      setVisible={setVisible}
      showConfirm={showConfirm}
      type={type}
      onConfirm={onConfirm}
    />
  )
}
