import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { Button, ButtonType } from '../button'
import { Modal } from './Modal'

type ConfirmationDialogProps = {
  isVisible: boolean
  setVisible: (value: boolean) => void
  title: string
  text?: string
  cancelButtonText?: string
  cancelButtonType?: ButtonType
  onCancel?: () => void
  confirmButtonText?: string
  confirmButtonType?: ButtonType
  onConfirm?: () => void
  children?: ReactNode
  isConfirmButtonDisabled?: boolean
  focusTrapped?: boolean
  confirmButtonLoading?: boolean
  headerClassName?: string
  headerIcon?: any
  middleButtonText?: string
  middleButtonType?: ButtonType
  onMiddleButtonClick?: () => void
}

export const ConfirmationDialog = ({
  isVisible,
  setVisible,
  title,
  text,
  cancelButtonText = 'Cancel',
  cancelButtonType = 'secondary',
  onCancel = () => {},
  confirmButtonText = 'Confirm',
  confirmButtonType = 'warn',
  onConfirm = () => {},
  children,
  isConfirmButtonDisabled,
  focusTrapped,
  confirmButtonLoading,
  headerClassName,
  headerIcon: HeaderIcon,
  middleButtonText = '',
  middleButtonType = 'secondary',
  onMiddleButtonClick = () => {},
}: ConfirmationDialogProps) => (
  <Modal
    className='lg:min-w-[500px] lg:!max-w-[600px]'
    focusTrapped={focusTrapped}
    isVisible={isVisible}
    setVisible={setVisible}
    showCloseIcon={false}
  >
    <div>
      <Header className={headerClassName}>
        {HeaderIcon && <HeaderIcon className='w-5 mr-4' />} <div>{title}</div>
      </Header>
      <div className='p-6 pt-0'>
        <div className='text-center'>{text}</div>
        {children}
        <div className='flex mt-4 justify-between gap-4'>
          <Button
            fullWidth
            className='w-full'
            type={cancelButtonType}
            onClick={() => {
              onCancel()
              setVisible(false)
            }}
          >
            {cancelButtonText}
          </Button>
          {!!middleButtonText && (
            <Button
              fullWidth
              className='w-full'
              type={middleButtonType}
              onClick={onMiddleButtonClick}
            >
              {middleButtonText}
            </Button>
          )}
          <Button
            fullWidth
            className='w-full'
            disabled={isConfirmButtonDisabled}
            loading={confirmButtonLoading}
            type={confirmButtonType}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
)

const Header = tw.div`
  text-sm
  font-bold
  flex
  items-center
  justify-center
  p-6
  pb-3
`
