import { ButtonType } from './types'

export const classNames = (...classes: Array<string>): string => classes.filter(Boolean).join(' ')

export const getButtonClassNamesByType = (
  type: ButtonType,
  noBackground: boolean,
  backgroundColor: string,
) => {
  let classNames = ''

  switch (type) {
    case 'warn':
      classNames = `${
        noBackground ? 'text-brand-accent' : 'bg-brand-accent text-dark-blue'
      } border border-solid border-brand-accent`
      break
    case 'danger':
      classNames = `${
        noBackground ? 'text-error' : 'bg-error text-white'
      } border border-solid border-error`
      break
    case 'primary':
      classNames = `${
        noBackground ? 'text-dark-blue' : 'bg-dark-blue text-white'
      } border border-solid border-dark-blue`
      break
    case 'secondary':
      classNames = `${
        noBackground ? 'text-white' : 'bg-white text-dark-blue'
      } border border-solid border-dark-blue`
      break
    case 'success':
      classNames = `${
        noBackground ? 'text-success' : 'bg-success text-white'
      } border border-solid border-success`
      break
    case 'link':
      classNames = `${
        noBackground ? 'text-link' : 'bg-link text-white'
      } border border-solid border-link`
      break
    case 'light':
      classNames = `${
        noBackground ? 'text-white' : 'bg-white text-dark-blue'
      } border border-solid border-white`
      break
    default:
      classNames = `${
        noBackground ? 'text-dark-blue' : 'bg-dark-blue text-white'
      } border border-solid border-dark-blue`
  }

  if (backgroundColor) classNames = `text-dark-blue ${backgroundColor}`

  return classNames
}
