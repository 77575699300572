// Returns true if email string follows an email pattern or if it's blank and not required, otherwise false
import dayjs from 'dayjs'
import {
  camelCase,
  isArray,
  isEqual,
  isPlainObject,
  mapKeys,
  mapValues,
  snakeCase,
} from 'lodash-es'

import { PopularCityLocation } from '../../components'
import { canadianProvinces, mexicanStates } from '../constants'
import { ApiErrorResponse, TableOrder } from '../types'

export const validateEmail = (email: string, required = false) =>
  (!required && !email.length) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

// Returns true if phone string is 10 digits or if it's blank and not required, otherwise false
export const validatePhoneNumber = (phone: string, required = false) =>
  (!required && !phone?.length) || phone?.replace(/\D/g, '').length === 10

export const countryAbbreviationToName = (abbreviation = ''): string => {
  const countryAbbreviations: { [key: string]: string } = {
    MX: 'Mexico',
    US: 'US',
    CA: 'Canada',
  }

  return countryAbbreviations[abbreviation]
}

export const getStateNameFromCode = (code = '', country = 'US') =>
  country === 'US' ? code : { ...mexicanStates, ...canadianProvinces }[code.toUpperCase()] || ''

// Maps a two or thee-letter country code to MEX, CAN, or USA
export const mapCountryCode = (countryCode = ''): string => {
  const countryMapping: {
    [key: string]: string
  } = {
    MX: 'MEX',
    MEX: 'MEX',
    US: 'USA',
    USA: 'USA',
    CA: 'CAN',
    CAN: 'CAN',
  }

  return countryMapping[countryCode] || countryCode
}

// Accepts a number, and returns it formatted as a currency with decimals. e.g. 1000 -> $1,000.00
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'USD',
})

// Accepts a number, and returns it as a whole dollar amount. e.g. 1000 -> $1,000
export const dollarAmountOnly = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  style: 'currency',
  currency: 'USD',
})

export const formatCountAndPluralize = (count: number, noun: string, suffix?: string) =>
  `${count} ${pluralizeNoun(count, noun, suffix)}`

export const pluralizeNoun = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`

export const snakeToTitleCase = (str: string) =>
  str
    .split('_') // split string on underscores
    .map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(), // capitalize first letter of each word
    )
    .join(' ') // join words with a space

/**
 * Turns an ApiErrorResponse into:
 * comma separated validation or regular error detail(s)
 * Most errors are singular, but validation
 * errors can come in multiples
 */
const getApiErrorResponseString = (response: ApiErrorResponse): string => {
  if (response.errors instanceof Array && response.errors.length > 0) {
    return response.errors
      .map((error, index) => {
        const detail = error.attr
          ? `${snakeToTitleCase(error.attr)}: ${error.detail}`
          : error.detail
        return `${detail}${index < response.errors.length - 1 ? ', ' : ''}`
      })
      .join('')
  } else {
    return JSON.stringify(response, null, 2)
  }
}

/**
 * Turns a response of type { detail: string }, { message: string } into its plain string
 * value
 */
const getDeprecatedErrorResponseString = (response: any): string | null => {
  if (response.detail != null) {
    return String(response.detail)
  } else if (response.message != null) {
    return String(response.message)
  } else if (typeof response === 'string') {
    return response
  } else {
    return null
  }
}

const isNewApiErrorResponseFormat = (response: any): boolean => response?.type && response?.errors
const isDeprecatedErrorResponseFormat = (response: any): boolean =>
  response?.detail || response?.message || typeof response === 'string'

/**
 * Allows for converting an ApiErrorResponse into a more user-friendly-ish string. If it isn't able to
 * convert, it will use the `fallback` string as the return.
 * See [getApiErrorResponseString] or [getDeprecatedErrorResponseString] for response parameter and result
 */
export const getErrorString = (
  response: any,
  fallback = 'An unexpected error has occurred. Please try to refresh the page.',
  maxLength = 300,
): string => {
  let errorString: string | null = null
  if (isNewApiErrorResponseFormat(response)) {
    errorString = getApiErrorResponseString(response)
  } else if (isDeprecatedErrorResponseFormat(response)) {
    errorString = getDeprecatedErrorResponseString(response)
  }

  if (!errorString || errorString.length > maxLength) return fallback
  return errorString
}

// Helper function to calculate the difference between two dates in milliseconds
export const dateDiff = (date1: Date, date2: Date) => date1.getTime() - date2.getTime()

export const formatDateForBackend = (date: Date | null | string) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null

/*
  Accepts an Array of Date | null, (e.g. [Date, Date], [null, null], [Date, null]) as the first argument and the field name (e.g. load__paid_date) as the second argument
  In case of [Date, Date] the function will return:
  {
    load__paid_date__gte: dates[0],
    load__paid_date__lte: dates[1]
  }
  In case of [Date, null] the function will return:
  {
    load__paid_date__exact: dates[0],
  }
*/
export const formatDateFilterForBackend = (dateRange: any = [], field: string) => {
  if (!dateRange) return {}

  const dates = dateRange.map((date: any) => formatDateForBackend(date))

  if (isEqual(dates?.[0], dates?.[1])) return { [field]: dates?.[0] }

  return {
    ...(dates?.[0] &&
      !dates?.[1] && {
        [field]: dates?.[0],
      }),
    ...(dates?.every((date: Date | null) => date) && {
      [`${field}__gte`]: dates?.[0],
      [`${field}__lte`]: dates?.[1],
    }),
  }
}

export const randomString = () => Math.random().toString(36).substring(2, 9)

export const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No')

// Returns an object containing properties from the first object that have different values compared to the second object
export const getObjectDifferences = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): Record<string, any> => {
  const hasOwn = (obj: Record<string, any>, key: string) =>
    Object.prototype.hasOwnProperty.call(obj, key)
  const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item)

  const differences: Record<string, any> = {}

  for (const key in obj1) {
    if (hasOwn(obj2, key)) {
      if (isObject(obj1[key]) && isObject(obj2[key])) {
        const nestedDifferences = getObjectDifferences(obj1[key], obj2[key])
        if (Object.keys(nestedDifferences).length !== 0) differences[key] = nestedDifferences
      } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) differences[key] = obj1[key]
      } else if (obj1[key] !== obj2[key]) differences[key] = obj1[key]
    } else differences[key] = obj1[key]
  }

  return differences
}

export const keysToCamelCase = (obj: any): any => {
  if (isArray(obj)) return obj.map(keysToCamelCase)
  else if (isPlainObject(obj))
    return mapKeys(mapValues(obj, keysToCamelCase), (_, key) => camelCase(key))

  return obj
}

export const keysToSnakeCase = (obj: any): any => {
  if (isArray(obj)) return obj.map(keysToSnakeCase)
  else if (isPlainObject(obj))
    return mapKeys(mapValues(obj, keysToSnakeCase), (_, key) => snakeCase(key))

  return obj
}

// Replaces specific location abbreviations in a query string with their full forms
export const replaceLocationAbbreviations = (query: string): string => {
  const replacements: { [key: string]: string } = {
    '\\bmt\\.?\\b': 'mount',
    '\\bft\\.?\\b': 'fort',
    '\\bst(?!\\.)\\b': 'st.',
    '\\bsaint\\b': 'st.',
  }

  let result = query.replace(/\./g, '')

  for (const pattern in replacements) {
    const regex = new RegExp(pattern, 'gi')
    result = result.replace(regex, replacements[pattern])
  }

  return result
}

// Checks if a string starts with a given query string, case-insensitively
export const startsWith = (str: string, query: string) => str.toLowerCase().startsWith(query)

// Filters and sorts a list of popular cities based on a query string and optional country codes, returning the top 20 matches
export const getPopularCitiesSearchResults = (
  popularCities: PopularCityLocation[],
  query: string,
  countries = ['US'],
) =>
  popularCities
    .filter(
      city =>
        [(c: string, s: string) => `${c} ${s}`, (c: string, s: string) => `${c}, ${s}`].some(fmt =>
          fmt(city[0], getStateNameFromCode(city[1], city[2]))
            .toLowerCase()
            .includes(query.toLowerCase()),
        ) && countries.includes(city[2]),
    )
    .sort((a, b) =>
      startsWith(a[0], query) && !startsWith(b[0], query)
        ? -1
        : !startsWith(a[0], query) && startsWith(b[0], query)
          ? 1
          : 0,
    )
    .slice(0, 20)

// Normalizes a query string by replacing location abbreviations, converting to lowercase, trimming, and reducing multiple spaces to a single space
export const getNormalizedQuery = (query: string) =>
  replaceLocationAbbreviations(query).toLowerCase().trim().replace(/\s+/g, ' ')

// Formats a city location result into an object containing city, state, country, latitude, longitude, and a formatted title
export const formatPopularLocation = (result: PopularCityLocation) => {
  const title = `${result[0]}, ${getStateNameFromCode(
    result[1],
    result[2],
  )}, ${countryAbbreviationToName(result[2])}`

  return {
    city: result[0],
    state: result[1],
    country: result[2],
    latitude: result[3],
    longitude: result[4],
    title,
    name: title,
  }
}

/*
  Accepts current table order, headers available for ordering, and the label that was clicked
  Returns the display object for a table order.
 */
export const getTableOrderDisplay = (
  headers: Array<{ label: string; key: string }>,
  label: string,
  orderBy?: TableOrder,
) => {
  const isAscending = orderBy?.direction === 'ascending'
  const header = headers.find(header => header.label === label)
  if (!header) return {}

  const key = header.key

  return orderBy?.label === label
    ? {
        label: isAscending ? label : '',
        direction: isAscending ? 'descending' : '',
        key: isAscending ? key : '',
      }
    : { label: label, direction: 'ascending', key }
}

// Helper function to format time difference in milliseconds to human-readable format (e.g. 1 hour 30 minutes)
export const durationToReadableTime = (timeDiff: number) => {
  const hours = Math.floor(timeDiff / (60 * 60 * 1000))
  const minutes = Math.round((timeDiff % (60 * 60 * 1000)) / (60 * 1000))
  let formattedTime = ''

  if (hours > 0) {
    formattedTime += formatCountAndPluralize(hours, 'hour')
  }
  if (minutes > 0) {
    if (formattedTime) formattedTime += ' '
    formattedTime += formatCountAndPluralize(minutes, 'minute')
  }
  return formattedTime || '0 minutes'
}

export const capitalize = (s = '') => `${s[0]?.toUpperCase()}${s?.slice(1)}`

export const setCityCase = (city: string) => city.toLowerCase().split(' ').map(capitalize).join(' ')

export const displayCityAndState = (city: any, state: any) => {
  const builder = []
  if (city) builder.push(setCityCase(city))
  if (state) builder.push(state.toUpperCase())
  return builder.join(', ')
}

export const formatDate = (date?: string) => (date ? dayjs(date).format('MM/DD/YYYY') : '—')

export const formatArrayOfIds = (array?: (string | number)[], returnString = true) =>
  returnString
    ? array?.join(',')
    : array?.map((el: number | string) => (!isNaN(Number(el)) ? Number(el) : el))
