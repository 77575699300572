import './TextWithTooltip.scss'

import React from 'react'
import Tooltip from 'react-tooltip-lite'
import tw from 'tailwind-styled-components'

export const TextWithTooltip = ({
  children = '',
  limit = 14,
  className,
}: {
  children: string
  limit?: number
  className?: string
}) => (
  <Container className={className}>
    {children?.length > limit ? (
      // @ts-ignore
      <Tooltip content={<div style={{ maxWidth: '500px' }}>{children}</div>}>
        <div className='w-full overflow-ellipsis truncate'>{children}</div>
      </Tooltip>
    ) : (
      <div>{children}</div>
    )}
  </Container>
)

const Container = tw.div`
  w-28
`
