import { getConfirmation, MoreDropdown } from '@components'
import { toast } from 'react-toastify'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { archiveLoad } from '../../../redux/loadsSlice'

export const LoadActions = ({ setTab = () => {} }: { setTab?: (tab: string) => void }) => {
  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)

  const copyTrackingLink = () => {
    navigator.clipboard.writeText(`${location.origin}/tracking/${load?.trackingNumber}`)
    toast.success('Successfully copied tracking link')
  }

  const actions = [
    {
      label: 'Copy Tracking Link',
      onClick: copyTrackingLink,
    },
    {
      label: 'Archive Load',
      onClick: async () => {
        const response = await getConfirmation({
          message: 'Are you sure you want to archive this load?',
          type: 'danger',
          actionLabel: 'Yes, archive',
        })
        if (response) dispatch(archiveLoad(load?.id))
      },
    },
  ]

  return (
    <>
      <div className='hidden lg:block'>
        <MoreDropdown withBorder className='!z-[99]' dropdownClassName='right-10' items={actions} />
      </div>
      <div className='lg:hidden'>
        {actions.map(action => (
          <MobileItem
            key={action.label}
            onClick={() => {
              action.onClick()
              setTab('main')
            }}
          >
            {action.label}
          </MobileItem>
        ))}
      </div>
    </>
  )
}

const MobileItem = tw.div`
  py-4
  px-4
  border-b
  border-light-gray
  last:border-none
  font-semibold
`
