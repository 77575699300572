import { AppLogoImage, Button, Form, TextInput } from '@components'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { getDocumentTitle, getPhoneMaskOptions } from '../../common/utils'
import { Error, Help, Link } from '../../components/Login/components'
import { registerShipper, setRegister, setStep } from '../../redux/userSlice'

const phoneMask = getPhoneMaskOptions()

export const RegisterScreen = (): JSX.Element => {
  const dispatch = useAppThunkDispatch()
  const navigate = useNavigate()
  const [isFormValid, setFormValid] = useState(false)
  const register = useAppSelector(state => state.user.register)
  const isRegistering = useAppSelector(state => state.user.loading.registerShipper)
  const credential = useAppSelector(state => state.user.credentials)

  const onRegister = useCallback(async () => {
    const response = await dispatch(registerShipper())
    if (response.meta.requestStatus === 'rejected') return

    dispatch(setStep('CONFIRM'))
    navigate('/')
  }, [])

  useEffect(() => {
    document.title = getDocumentTitle('Register')
  }, [])

  return (
    <Container>
      <Image alt='login' src='/login-cover.svg' />
      <FormContainer>
        <div className='flex flex-col items-center mb-10'>
          <AppLogoImage className='w-[90px] mb-10' variant='icon' />
          <div className='ml-4 text-3xl font-semibold'>Let&apos;s get started</div>
        </div>
        <Form
          autoComplete='off'
          className='flex flex-col gap-4 w-80 items-center'
          setFormValid={setFormValid}
        >
          <TextInput
            required
            sm
            className='w-full'
            label='Company Name'
            value={register.companyName}
            onChange={companyName => dispatch(setRegister({ companyName }))}
          />
          <div className='flex gap-2'>
            <TextInput
              required
              sm
              className='w-full'
              label='First Name'
              value={register.firstName}
              onChange={firstName => dispatch(setRegister({ firstName }))}
            />
            <TextInput
              required
              sm
              className='w-full'
              label='Last Name'
              value={register.lastName}
              onChange={lastName => dispatch(setRegister({ lastName }))}
            />
          </div>
          <TextInput
            required
            sm
            className='w-full'
            label='Email'
            type='email'
            value={credential.username}
          />
          <TextInput
            required
            sm
            className='w-full'
            label='Phone Number'
            maskOptions={phoneMask}
            value={register.phone}
            onChange={phone => dispatch(setRegister({ phone }))}
          />
          <Error>{''}</Error>
          <Button
            fullWidth
            lg
            className='w-full'
            disabled={!isFormValid}
            loading={isRegistering}
            type='primary'
            onClick={onRegister}
          >
            Create Account
          </Button>
        </Form>
        <div className='text-center'>
          <Link onClick={() => navigate('/')}>Back to login</Link>
          <Help />
        </div>
      </FormContainer>
    </Container>
  )
}

const Container = tw.div`
  block
  md:!flex
  h-screen
  fixed
  md:!static
  top-0
`

const Image = tw.img`
  w-full
  h-54
  md:!w-1/2
  md:h-full
  object-cover
`

const FormContainer = tw.div`
  flex
  md:w-1/2
  h-full
  flex-col
  justify-center
  mt-10
  md:mt-0
  items-center
  gap-6
`
