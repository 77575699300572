import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { emptyLocation } from '../common/constants'
import { trackEvent } from '../common/tracking'
import { CustomerQuote, NewLoadLocation, NewManifestItem } from '../common/types'
import { keysToCamelCase, keysToSnakeCase, normalizeLoadPayload } from '../common/utils'
import { setSingleLaneQuote } from './commonSlice'
import { getLoads, getProjectLoads } from './loadsSlice'
import { getAllQuotes, getQuoteDetails } from './quotingToolSlice'

export type RateBookingDetails = {
  documents: { file: string; name: string; id: string }[]
  modalStep: number
  refId?: string
}

export type NewLoadDimensions = {
  width: string
  height: string
  length: string
  weight: string
  temperature: string
  description: string
}

type CreateNewLoadState = {
  loading: {
    createLoad: boolean
    saveLoad: boolean
  }
  origin: NewLoadLocation
  destination: NewLoadLocation
  loadStops: NewLoadLocation[]
  currentStep: number
  completedSteps: number[]
  equipmentType: string
  specialRequirements: number[]
  pickupItems: NewManifestItem[]
  dropoffItems: NewManifestItem[]
  unNumber: string
  highValueAmount: string
  refId: string
  poNumbers: Array<{ id?: string; name: string }>
  dimensions: NewLoadDimensions
  isRouteDetailsFormValid: boolean
  isCreationModalVisible: boolean
  isDraftCreationModalVisible: boolean
  isEditMode: boolean
  quoteDetails: CustomerQuote | null
  isBookQuoteModalVisible: boolean
  isCreationWithPDFModalVisible: boolean
  rateBookingDetails: RateBookingDetails
}

const initialState: CreateNewLoadState = {
  loading: {
    createLoad: false,
    saveLoad: false,
  },
  origin: emptyLocation,
  destination: emptyLocation,
  loadStops: [],
  currentStep: 0,
  completedSteps: [],
  equipmentType: '',
  specialRequirements: [],
  pickupItems: [],
  dropoffItems: [],
  unNumber: '',
  highValueAmount: '',
  refId: '',
  poNumbers: [],
  dimensions: { width: '', height: '', length: '', weight: '', temperature: '', description: '' },
  isRouteDetailsFormValid: false,
  isCreationModalVisible: false,
  isDraftCreationModalVisible: false,
  isEditMode: false,
  quoteDetails: null,
  isBookQuoteModalVisible: false,
  isCreationWithPDFModalVisible: false,
  rateBookingDetails: { modalStep: 0, documents: [] },
}

const ORDER_TYPE_ACCEPTED_TENDER = 3
const EXO_NETWORK = 2
const PRIVATE_NETWORK = 4
const LOAD_STATUS_QUOTED = 20

export const createMultipleLoads = createAsyncThunk(
  'createLoad/createMultipleLoads',
  async (
    { projectId, numberOfLoads }: { projectId: number; numberOfLoads: number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { origin, destination, loadStops, equipmentType, dimensions } = (getState() as RootState)
      .createNewLoad

    const payload = {
      ...normalizeLoadPayload(
        origin,
        destination,
        loadStops,
        equipmentType,
        [],
        '',
        '',
        '',
        false,
        [],
        dimensions,
      ),
      projectId,
      bulk: numberOfLoads,
      network: PRIVATE_NETWORK,
    }

    try {
      const response = await api.post(
        '/loads/api/v1/external/create-bulk-load/',
        keysToSnakeCase(payload),
      )
      dispatch(getProjectLoads(projectId))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createLoad = createAsyncThunk(
  'createLoad/createNewLoad',
  async ({ emptyLoad }: { emptyLoad?: boolean }, { getState, dispatch, rejectWithValue }) => {
    const {
      quoteDetails,
      rateBookingDetails,
      origin,
      destination,
      loadStops,
      equipmentType,
      specialRequirements,
      unNumber,
      highValueAmount,
      refId,
      dimensions,
    } = (getState() as RootState).createNewLoad
    const { singleLaneQuote } = (getState() as RootState).common

    const payload = {
      quoteId: quoteDetails?.id,
      orderType: ORDER_TYPE_ACCEPTED_TENDER,
      ...normalizeLoadPayload(
        emptyLoad
          ? {
              location: {
                ...quoteDetails?.originLocation,
                latitude: quoteDetails?.originLocation.lat,
                longitude: quoteDetails?.originLocation.lng,
              },
              date: quoteDetails?.pickupDate,
            }
          : origin,
        emptyLoad
          ? {
              location: {
                ...quoteDetails?.destinationLocation,
                latitude: quoteDetails?.destinationLocation.lat,
                longitude: quoteDetails?.destinationLocation.lng,
              },
            }
          : destination,
        emptyLoad
          ? (quoteDetails?.stopovers || [])?.map(stop => ({
              type: stop.stopType,
              location: {
                ...stop,
                latitude: stop.lat,
                longitude: stop.lng,
              },
            }))
          : loadStops,
        equipmentType || quoteDetails?.equipmentType || '',
        specialRequirements,
        unNumber,
        highValueAmount,
        refId || rateBookingDetails.refId || '',
        emptyLoad,
        rateBookingDetails?.documents || [],
        dimensions,
      ),
    }

    if (quoteDetails && singleLaneQuote) {
      try {
        const response = await api.post(
          '/loads/api/v1/external/create-load/',
          keysToSnakeCase({ ...payload, network: EXO_NETWORK, loadStatus: LOAD_STATUS_QUOTED }),
        )
        if (quoteDetails.id === singleLaneQuote.id) {
          dispatch(
            setSingleLaneQuote({
              ...singleLaneQuote,
              load: response.data.id,
              quotes: (singleLaneQuote?.quotes || []).map((slQuote: any) => ({
                ...slQuote,
                ...(slQuote.pickupDate === quoteDetails.pickupDate && {
                  load: response.data.id,
                  confirmed: true,
                }),
              })),
            }),
          )
        }
        dispatch(getAllQuotes())
        dispatch(getQuoteDetails(quoteDetails.id))
        dispatch(getLoads())
        return response.data.id
      } catch (err: CatchError) {
        return rejectWithValue(formatAxiosErrorToPayload(err))
      }
    } else {
      try {
        const response = await api.post(
          '/loads/api/v1/external/create-load/',
          keysToSnakeCase({ ...payload, network: PRIVATE_NETWORK }),
        )
        if (quoteDetails?.id) {
          dispatch(getAllQuotes())
          dispatch(getQuoteDetails(quoteDetails.id))
        }
        dispatch(getLoads())
        return response.data.id
      } catch (err: CatchError) {
        return rejectWithValue(formatAxiosErrorToPayload(err))
      }
    }
  },
)

export const confirmQuoteEDI = createAsyncThunk(
  'quotes/confirmQuoteEDI',
  async (quote: CustomerQuote | undefined, { rejectWithValue, getState, dispatch }) => {
    let quoteDetails: CustomerQuote | undefined | null
    let refId: string | undefined | null

    if (quote) {
      quoteDetails = quote
      refId = quote.refId
    } else {
      quoteDetails = (getState() as RootState).createNewLoad.quoteDetails
      refId = (getState() as RootState).createNewLoad.rateBookingDetails.refId
    }

    const { singleLaneQuote } = (getState() as RootState).common

    if (!quoteDetails) throw new Error('Cannot book EDI quote')

    try {
      const response = await api.post('/pricing/confirm-quote-edi/', {
        quote_id: quoteDetails.id,
        pickup_date: quoteDetails.pickupDate,
        customer_reference_id: refId,
      })

      dispatch(
        setSingleLaneQuote({
          ...singleLaneQuote,
          quotes: (singleLaneQuote?.quotes || []).map((slQuote: any) => ({
            ...slQuote,
            ...(slQuote.pickupDate === quoteDetails?.pickupDate && {
              reserved: true,
            }),
          })),
        }),
      )
      dispatch(getQuoteDetails(quoteDetails.id))
      dispatch(getAllQuotes())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const createNewLoadSlice = createSlice({
  name: 'createNewLoad',
  initialState,
  reducers: {
    setOrigin(state, { payload }) {
      state.origin = payload
    },
    setDestination(state, { payload }) {
      state.destination = payload
    },
    setLoadStops(state, { payload }) {
      state.loadStops = payload
    },
    setEquipmentType(state, { payload }) {
      state.equipmentType = payload
    },
    setRefId(state, { payload }) {
      state.refId = payload
    },
    setDimensions(state, { payload }) {
      state.dimensions = payload
    },
    setQuoteDetails(state, { payload }) {
      state.quoteDetails = payload
    },
    setRateBookingDetails(state, { payload }) {
      state.rateBookingDetails = payload
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createLoad.pending, state => {
        state.loading.createLoad = true
      })
      .addCase(createLoad.fulfilled, (state, { payload }) => {
        state.loading.createLoad = false
        state.isBookQuoteModalVisible = false
        state.isDraftCreationModalVisible = false
        if (payload) {
          trackEvent('Created Load')
          toast.success('Successfully created load')
        }
      })
      .addCase(createLoad.rejected, (state, { payload }) => {
        state.loading.createLoad = false
        toast.error(getErrorString(payload, 'Failed to create load'))
      })
      .addCase(createMultipleLoads.pending, state => {
        state.loading.createLoad = true
      })
      .addCase(createMultipleLoads.fulfilled, state => {
        state.loading.createLoad = false
        trackEvent('Created multiple loads')
        toast.success('Your loads are being created. Please wait')
      })
      .addCase(createMultipleLoads.rejected, (state, { payload }) => {
        state.loading.createLoad = false
        toast.error(getErrorString(payload, 'Failed to create loads'))
      })
      .addCase(confirmQuoteEDI.pending, state => {
        state.loading.createLoad = true
      })
      .addCase(confirmQuoteEDI.fulfilled, state => {
        state.loading.createLoad = false
        state.isBookQuoteModalVisible = false
        toast.warn(
          `You have successfully booked ${state.quoteDetails?.origin} → ${state.quoteDetails?.destination} lane. Please forward your load over EDI at least 4 hours before the desired pick up time.`,
          {
            icon: false,
            style: { minWidth: '900px', right: '588px' },
            autoClose: 15000,
          },
        )
      })
      .addCase(confirmQuoteEDI.rejected, (state, { payload }) => {
        state.loading.createLoad = false
        toast.error(getErrorString(payload, 'Failed to update quote'))
      })
  },
})

export const {
  setOrigin,
  setDestination,
  setLoadStops,
  setEquipmentType,
  setRefId,
  setQuoteDetails,
  setRateBookingDetails,
  reset,
  setDimensions,
} = createNewLoadSlice.actions

export default createNewLoadSlice.reducer
