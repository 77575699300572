import { broadcastLogin } from '@common'
import { Button, Form, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { isLocal, isPreview } from '../../../common/constants'
import { sendOTP, setCompanyId, setStep, verifyOTP } from '../../../redux/userSlice'
import { Error, Link } from '../components'

export const ConfirmStep = () => {
  const dispatch = useAppThunkDispatch()
  const error = useAppSelector(state => state.user.error)
  const email = useAppSelector(state => state.user.credentials.username)
  const isVerifyingOTP = useAppSelector(state => state.user.loading.verifyOTP)
  const [isFormValid, setFormValid] = useState(false)
  const [otp, setOTP] = useState(isLocal || isPreview ? '987654' : '')

  const onConfirm = async () => {
    const response = await dispatch(verifyOTP(otp))
    if (response.meta.requestStatus === 'rejected') return
    localStorage.setItem('lastAuthedEmail', email)

    if (response.payload.customerCompanyId) {
      dispatch(setCompanyId(response.payload.customerCompanyId))
      broadcastLogin()
    } else {
      dispatch(setStep('SELECT_COMPANY'))
    }
  }

  return (
    <Form autoComplete='off' className='flex flex-col items-center' setFormValid={setFormValid}>
      <TextInput
        required
        sm
        className='w-full mb-6'
        label='Verification Code'
        value={otp}
        onChange={setOTP}
      />
      {error && <Error>{error}</Error>}
      <Button
        fullWidth
        lg
        className='w-full'
        disabled={!isFormValid}
        loading={isVerifyingOTP}
        type='primary'
        onClick={onConfirm}
      >
        Confirm
      </Button>
      <div className='flex flex-col items-center gap-2 mt-4'>
        <Link onClick={() => dispatch(setStep('LOGIN'))}>Back to login</Link>
        <Link
          onClick={() => {
            dispatch(sendOTP())
              .unwrap()
              .then(() => toast.success('Resent confirmation email'))
              .catch(noop)
          }}
        >
          Resend Confirmation Email
        </Link>
      </div>
    </Form>
  )
}
