import { ListResponse } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'

export type ItemTemplateFile = {
  id: number | string
  file: File | string
  fileName?: string
  createdAt?: string
}

export type ItemTemplate = {
  id?: number
  name: string
  notes: string
  width: string
  height: string
  length: string
  weight: string
  files?: ItemTemplateFile[]
  createdAt?: string
  updatedAt?: string
}

export type InitialState = {
  itemTemplates: ItemTemplate[]
  activeItem: ItemTemplate | null
  itemsCount: number
  loading: {
    getItemTemplates: boolean
    getItemTemplate: boolean
    archiveItemTemplate: boolean
    upsertItemTemplate: boolean
  }
}

const getInitialState = () =>
  ({
    itemTemplates: [],
    activeItem: null,
    itemsCount: 0,
    loading: {
      getItemTemplates: false,
      getItemTemplate: false,
      archiveItemTemplate: false,
      upsertItemTemplate: false,
    },
  }) as InitialState

export const getItemTemplates = createAsyncThunk(
  'itemTemplate/getItems',
  async (
    payload:
      | {
          search?: string
          limit?: number
          offset?: number
          order?: { direction: string; key: string }
        }
      | undefined,
  ) => {
    const { search = null, limit = 50, offset = 0, order } = payload || {}
    const orderingDirection = (order?.direction || 'descending') === 'descending' ? '-' : ''
    const ordering = `${orderingDirection}${order?.key || 'created_at'}`

    return api
      .get<ListResponse<ItemTemplate>>('/loads/api/items/', {
        params: {
          limit,
          offset,
          ordering,
          ...(search && { search }),
        },
      })
      .then(res => keysToCamelCase(res.data))
  },
)

export const getItemTemplate = createAsyncThunk('itemTemplate/getItem', async (id: number) =>
  api.get<ItemTemplate>(`/loads/api/items/${id}/`).then(res => keysToCamelCase(res.data)),
)

export const archiveItemTemplate = createAsyncThunk(
  'itemTemplate/archiveItemTemplate',
  async (id: number) => api.delete(`/loads/api/items/${id}/`),
)

export const upsertItemTemplate = createAsyncThunk(
  'itemTemplate/upsertItemTemplate',
  async (payload: ItemTemplate) => {
    const reqUrl = payload.id ? `/loads/api/items/${payload.id}/` : '/loads/api/items/'
    const reqMethod = payload.id ? 'put' : 'post'
    const preparedPayload = omit(
      {
        ...payload,
        fileIds: payload.files?.map(file => file.id),
      },
      'files',
    )

    return api[reqMethod]<ItemTemplate>(reqUrl, keysToSnakeCase(preparedPayload)).then(res =>
      keysToCamelCase(res.data),
    )
  },
)

export const uploadItemTemplateFile = createAsyncThunk(
  'itemTemplate/uploadItemTemplateFile',
  async ({ file, onProgress }: { file: File; onProgress: (progress: number) => void }) => {
    const form = new FormData()
    form.append('file', file)

    return api
      .post<ItemTemplateFile>(`/loads/api/items/files/`, form, {
        onUploadProgress: progressEvent => {
          if (!progressEvent.total) return

          const progressCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onProgress(Math.min(progressCompleted, 99))
        },
      })
      .then(res => keysToCamelCase(res.data))
      .finally(() => onProgress(100))
  },
)

const itemTemplateSlice = createSlice({
  name: 'items',
  initialState: getInitialState(),
  reducers: {
    setItems(state, { payload }) {
      state.itemTemplates = payload
    },
    setActiveItem(state, { payload }) {
      state.activeItem = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItemTemplates.pending, state => {
        state.loading.getItemTemplates = true
      })
      .addCase(getItemTemplates.fulfilled, (state, { payload }) => {
        state.loading.getItemTemplates = false
        state.itemTemplates = payload.results
        state.itemsCount = payload.count
      })
      .addCase(getItemTemplates.rejected, state => {
        state.loading.getItemTemplates = false
        toast.error('Failed to fetch items')
      })
      .addCase(getItemTemplate.pending, state => {
        state.loading.getItemTemplate = true
      })
      .addCase(getItemTemplate.fulfilled, (state, { payload }) => {
        state.loading.getItemTemplate = false
        state.activeItem = payload
      })
      .addCase(getItemTemplate.rejected, state => {
        state.loading.getItemTemplate = false
        toast.error('Failed to fetch item')
      })
      .addCase(upsertItemTemplate.pending, state => {
        state.loading.upsertItemTemplate = true
      })
      .addCase(upsertItemTemplate.fulfilled, (state, { payload, meta }) => {
        state.loading.upsertItemTemplate = false
        state.activeItem = payload

        // Get the index if we have an id. If new or not found, idx will be -1
        const idx = meta.arg.id ? state.itemTemplates.findIndex(item => item.id === payload.id) : -1

        // If the item exists, update it. If not, add it to top of the list
        if (idx !== -1) {
          state.itemTemplates[idx] = payload
        } else if (!meta.arg.id) {
          state.itemTemplates.unshift(payload)
        }
        toast.success('Item saved')
      })
      .addCase(upsertItemTemplate.rejected, state => {
        state.loading.upsertItemTemplate = false
        toast.error('Failed to save item')
      })
      .addCase(archiveItemTemplate.pending, state => {
        state.loading.archiveItemTemplate = true
      })
      .addCase(archiveItemTemplate.fulfilled, (state, { meta }) => {
        state.itemTemplates = state.itemTemplates.filter(item => item.id !== meta.arg)
        toast.success('Item archived')
      })
      .addCase(archiveItemTemplate.rejected, state => {
        state.loading.archiveItemTemplate = false
        toast.error('Failed to archive item')
      })
  },
})

export const { setItems, setActiveItem } = itemTemplateSlice.actions
export default itemTemplateSlice.reducer
