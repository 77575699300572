import './Sidebar.scss'

import { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../app/hooks'
import { SidebarLink } from '../../common/types'
import { links } from './links'

const isLinkActive = (currentPath: string, url: string) =>
  (url === '/' && currentPath === url) || (url !== '/' && currentPath.startsWith(url))

export const Sidebar = (): JSX.Element | null => {
  const location = useLocation()

  const user = useAppSelector(state => state.user.user)

  const allowedLinks = useMemo(
    () =>
      links.filter(
        ({ displayIf }) =>
          !displayIf ||
          displayIf.every(
            condition =>
              ({
                portalQuotingEnabled: user?.portalQuotingEnabled,
                shipperContractLanes: user?.featureFlags?.['shipperContractLanes'],
                shipperPriceEstimator: user?.featureFlags?.['shipperPriceEstimator'],
                projectLoad: user?.featureFlags?.['projectLoad'],
              })[condition],
          ),
      ),
    [user],
  )

  return (
    <ContainerDiv>
      <CreateLoadButton to='/loads/new-load'>Create Load</CreateLoadButton>
      {allowedLinks.map(link =>
        link.external ? (
          <a key={link.url} href={link.url} rel='noreferrer' target='_blank'>
            <LinkContent link={link} />
          </a>
        ) : (
          <NavLink
            key={link.url}
            className={() => (isLinkActive(location.pathname, link.url) ? 'text-brand-accent' : '')}
            to={link.url}
          >
            <LinkContent link={link} />
          </NavLink>
        ),
      )}
    </ContainerDiv>
  )
}

const LinkContent = ({ link }: { link: SidebarLink }) => (
  <div className='flex items-center h-12'>
    <div className='w-16'>
      <IconBox $active={isLinkActive(location.pathname, link.url)}>{link.icon}</IconBox>
    </div>
    <LinkLabel>{link.label}</LinkLabel>
  </div>
)

// Replaced Button with NavLink to allow opening the load creation screen in a new tab
const CreateLoadButton = tw(NavLink)`
  text-white
  font-poppins
  font-semibold
  hover:!text-white
  bg-brand-dark
  p-2
  rounded-lg
  w-full
  mx-4
  mb-2
  flex
  items-center
  justify-center
  w-[168px]
  block
  text-center
  hover:opacity-80
  transition-all
`

const ContainerDiv = tw.div`
  sidebar
  hidden
  md:!block
  w-[200px]
  absolute
  bg-white
  z-20
  border-r 
  border-light-gray 
  h-full 
  pt-4
`

const LinkLabel = tw.div`
  w-full
  whitespace-nowrap
  font-semibold
  text-sm
  inline-flex
`

const IconBox = tw.div`
  py-4
  pl-6
  pr-4
  ${(p: { $active: boolean }) => p.$active && 'active-link'}
`
