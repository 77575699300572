import axios from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'

axiosBetterStacktrace(axios)

// for local, we use a proxy, so we don't need to set the base url
// Kristina: Keeping this until I'm done with the mobile version
// axios.defaults.baseURL = 'http://10.0.1.7:8000/'
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'csrftoken3'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export { axios as api }
