import tw from 'tailwind-styled-components'

export type TabObject = {
  label: string
  counter?: number
}

export type SingleTab = string | number | TabObject
export type ActiveTab = string | number

export type TabsProps<T extends SingleTab, R extends ActiveTab> = {
  tabs: T[] | readonly T[]
  setActiveTab?: (reference: R) => void
  activeTab?: R | null
  className?: string
}

export const Tabs = <T extends SingleTab, R extends ActiveTab>({
  tabs,
  activeTab,
  setActiveTab = () => {},
  className = '',
}: TabsProps<T, R>) => {
  const tabsList = tabs.map(tab => ({
    label: (tab as TabObject).label ?? tab,
    counter: (tab as TabObject).counter ?? null,
  }))

  return (
    <div className={className}>
      <WrappedTabs>
        {tabsList.map((tab, i) => (
          <Tab
            key={tab.label}
            $isActive={typeof activeTab === 'string' ? tab.label === activeTab : i === activeTab}
            data-testid={`tab${i}`}
            onClick={() => setActiveTab((typeof activeTab === 'string' ? tab.label : i) as R)}
          >
            {tab.label}
            {tab.counter ? <Counter>{tab.counter}</Counter> : null}
          </Tab>
        ))}
      </WrappedTabs>
    </div>
  )
}

const Counter = tw.span`
  bg-link
  rounded-full
  h-4
  px-1.5
  flex
  items-center
  justify-center
  text-[10px]
  ml-2
  text-white
`

const WrappedTabs = tw.div`
  flex
  items-center
  justify-between
  text-sm
  tracking-wider
  border
  border-light-blue
  rounded-lg
  p-0.5
`

const Tab = tw.div<{ $isActive: boolean }>`
  text-center
  items-center
  flex
  justify-center
  w-full 
  transition-all
  py-0.5
  px-4
  whitespace-nowrap
  cursor-pointer
  h-7
  ${({ $isActive }) =>
    $isActive ? 'bg-slate-200 rounded-lg text-link font-bold' : 'bg-white text-black'}
`
