import { DateInput, Select, TextInput } from '@components'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { loadStatusChoicesNew, quotingToolSupportedEquipmentTypes } from '../../../common/constants'
import { SearchFilters } from '../../../common/types'
import { getUploadsList } from '../../../redux/quotingToolSlice'
import { EquipmentTypeSelect } from '../../Selects'

export const FilterFields = ({
  filtersList,
  localFilters,
  setLocalFilters,
}: {
  filtersList: readonly (keyof SearchFilters)[]
  localFilters: SearchFilters
  setLocalFilters: (value: SearchFilters) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const uploadsList = useAppSelector(state => state.quotingTool.uploadsList)
  const uploadsListLoading = useAppSelector(state => state.quotingTool.loading.uploadsList)
  const contacts = useAppSelector(state => state.quotingTool.contacts)
  const contactsLoading = useAppSelector(state => state.quotingTool.loading.contacts)

  useEffect(() => {
    dispatch(getUploadsList())
  }, [])

  const filterItems: {
    type: keyof SearchFilters
    component: JSX.Element
  }[] = [
    {
      type: 'equipmentType',
      component: (
        <EquipmentTypeSelect
          withLabel
          className='w-full'
          setValue={(equipmentType: string) => setLocalFilters({ ...localFilters, equipmentType })}
          value={localFilters.equipmentType}
        />
      ),
    },
    {
      type: 'quoteEquipmentType',
      component: (
        <Select
          sm
          choices={quotingToolSupportedEquipmentTypes}
          label='Equipment Type'
          value={localFilters.quoteEquipmentType}
          onChange={(quoteEquipmentType: string) =>
            setLocalFilters({ ...localFilters, quoteEquipmentType })
          }
        />
      ),
    },
    {
      type: 'uploadFile',
      component: (
        <Select
          isMulti
          sm
          choices={uploadsList}
          field='file'
          label='File Name'
          loading={uploadsListLoading}
          value={localFilters.uploadFile}
          onChange={(uploadFile: any) => setLocalFilters({ ...localFilters, uploadFile })}
        />
      ),
    },
    {
      type: 'dateRanges',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Pickup Date'
          value={localFilters.dateRanges}
          onChange={(dateRanges: any) => setLocalFilters({ ...localFilters, dateRanges })}
        />
      ),
    },
    {
      type: 'dateQuoted',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Date Quoted'
          value={localFilters.dateQuoted}
          onChange={(dateQuoted: any) => setLocalFilters({ ...localFilters, dateQuoted })}
        />
      ),
    },
    {
      type: 'quoteId',
      component: (
        <TextInput
          sm
          label='Quote #'
          value={localFilters.quoteId}
          onChange={(quoteId: string) => setLocalFilters({ ...localFilters, quoteId })}
        />
      ),
    },
    {
      type: 'originCity',
      component: (
        <TextInput
          sm
          label='Origin City'
          value={localFilters.originCity}
          onChange={(originCity: string) => setLocalFilters({ ...localFilters, originCity })}
        />
      ),
    },
    {
      type: 'originState',
      component: (
        <TextInput
          sm
          label='Origin State'
          mask='aa'
          value={localFilters.originState}
          onChange={(originState: string) =>
            setLocalFilters({ ...localFilters, originState: originState.toUpperCase() })
          }
        />
      ),
    },
    {
      type: 'destinationCity',
      component: (
        <TextInput
          sm
          label='Destination City'
          value={localFilters.destinationCity}
          onChange={(destinationCity: string) =>
            setLocalFilters({ ...localFilters, destinationCity })
          }
        />
      ),
    },
    {
      type: 'destinationState',
      component: (
        <TextInput
          sm
          label='Destination State'
          mask='aa'
          value={localFilters.destinationState}
          onChange={(destinationState: string) =>
            setLocalFilters({ ...localFilters, destinationState: destinationState.toUpperCase() })
          }
        />
      ),
    },
    {
      type: 'loadsSearch',
      component: (
        <TextInput
          sm
          label='Search'
          value={localFilters.loadsSearch}
          onChange={(loadsSearch: string) => setLocalFilters({ ...localFilters, loadsSearch })}
        />
      ),
    },
    {
      type: 'pickupStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup Start Date'
          placeholder=''
          value={localFilters.pickupStartDate}
          onChange={(pickupStartDate: any) => setLocalFilters({ ...localFilters, pickupStartDate })}
        />
      ),
    },
    {
      type: 'pickupEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup End Date'
          placeholder=''
          value={localFilters.pickupEndDate}
          onChange={(pickupEndDate: any) => setLocalFilters({ ...localFilters, pickupEndDate })}
        />
      ),
    },
    {
      type: 'startDate',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Start Date'
          placeholder=''
          value={localFilters.startDate}
          onChange={(startDate: any) => setLocalFilters({ ...localFilters, startDate })}
        />
      ),
    },
    {
      type: 'endDate',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='End Date'
          placeholder=''
          value={localFilters.endDate}
          onChange={(endDate: any) => setLocalFilters({ ...localFilters, endDate })}
        />
      ),
    },
    {
      type: 'pickupEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup End Date'
          placeholder=''
          value={localFilters.pickupEndDate}
          onChange={(pickupEndDate: any) => setLocalFilters({ ...localFilters, pickupEndDate })}
        />
      ),
    },
    {
      type: 'deliveryStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Delivery Start Date'
          placeholder=''
          value={localFilters.deliveryStartDate}
          onChange={(deliveryStartDate: any) =>
            setLocalFilters({ ...localFilters, deliveryStartDate })
          }
        />
      ),
    },
    {
      type: 'deliveryEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Delivery End Date'
          placeholder=''
          value={localFilters.deliveryEndDate}
          onChange={(deliveryEndDate: any) => setLocalFilters({ ...localFilters, deliveryEndDate })}
        />
      ),
    },
    {
      type: 'loadStatuses',
      component: (
        <Select
          extractValue
          isMulti
          sm
          choices={loadStatusChoicesNew}
          field='name'
          label='Load Status'
          menuPlacement='bottom'
          value={localFilters.loadStatuses}
          valueField='key'
          onChange={(loadStatuses: any) => setLocalFilters({ ...localFilters, loadStatuses })}
        />
      ),
    },
    {
      type: 'projectStatus',
      component: (
        <Select
          extractValue
          sm
          choices={['Draft', 'Active', 'Completed']}
          label='Status'
          value={localFilters.projectStatus}
          onChange={(projectStatus: string) => setLocalFilters({ ...localFilters, projectStatus })}
        />
      ),
    },
    {
      type: 'user',
      component: (
        <Select
          extractValue
          sm
          choices={contacts}
          field='name'
          label='Created By'
          loading={contactsLoading}
          value={localFilters.user}
          valueField='userId'
          onChange={(user: { id: number; name: string; userId: number }) =>
            setLocalFilters({ ...localFilters, user })
          }
        />
      ),
    },
    {
      type: 'show',
      component: (
        <Select
          extractValue
          sm
          field='name'
          label='Show'
          value={localFilters.show}
          valueField='key'
          choices={[
            { key: true, name: 'Purchased' },
            { key: false, name: 'Available To Buy' },
          ]}
          onChange={(show: { key: boolean; name: string }) =>
            setLocalFilters({ ...localFilters, show })
          }
        />
      ),
    },
    {
      type: 'refId',
      component: (
        <TextInput
          sm
          label='Reference ID'
          value={localFilters.refId}
          onChange={(refId: string) => setLocalFilters({ ...localFilters, refId })}
        />
      ),
    },
    {
      type: 'invoicedStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Invoice Start Date'
          placeholder=''
          value={localFilters.invoicedStartDate}
          onChange={(invoicedStartDate: any) =>
            setLocalFilters({ ...localFilters, invoicedStartDate })
          }
        />
      ),
    },
    {
      type: 'invoicedEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Invoice End Date'
          placeholder=''
          value={localFilters.invoicedEndDate}
          onChange={(invoicedEndDate: any) => setLocalFilters({ ...localFilters, invoicedEndDate })}
        />
      ),
    },
    {
      type: 'loadId',
      component: (
        <TextInput
          sm
          label='Load ID'
          value={localFilters.loadId}
          onChange={loadId => setLocalFilters({ ...localFilters, loadId })}
        />
      ),
    },
    {
      type: 'projectId',
      component: (
        <TextInput
          sm
          label='Project ID'
          value={localFilters.projectId}
          onChange={projectId => setLocalFilters({ ...localFilters, projectId })}
        />
      ),
    },
    {
      type: 'projectName',
      component: (
        <TextInput
          sm
          label='Project Name'
          value={localFilters.projectName}
          onChange={projectName => setLocalFilters({ ...localFilters, projectName })}
        />
      ),
    },
    {
      type: 'name',
      component: (
        <TextInput
          sm
          label='Name'
          value={localFilters.name}
          onChange={name => setLocalFilters({ ...localFilters, name })}
        />
      ),
    },
    {
      type: 'id',
      component: (
        <TextInput
          sm
          label='ID'
          value={localFilters.id}
          onChange={id => setLocalFilters({ ...localFilters, id })}
        />
      ),
    },
    {
      type: 'mcNumber',
      component: (
        <TextInput
          sm
          label='MC Number'
          value={localFilters.mcNumber}
          onChange={mcNumber => setLocalFilters({ ...localFilters, mcNumber })}
        />
      ),
    },
    {
      type: 'dotNumber',
      component: (
        <TextInput
          sm
          label='DOT Number'
          value={localFilters.dotNumber}
          onChange={dotNumber => setLocalFilters({ ...localFilters, dotNumber })}
        />
      ),
    },
    {
      type: 'state',
      component: (
        <TextInput
          label='State'
          mask='aa'
          value={localFilters.state}
          onChange={state => setLocalFilters({ ...localFilters, state: state.toUpperCase() })}
        />
      ),
    },
    {
      type: 'city',
      component: (
        <TextInput
          label='City'
          value={localFilters.city}
          onChange={city => setLocalFilters({ ...localFilters, city })}
        />
      ),
    },
    {
      type: 'postalCode',
      component: (
        <TextInput
          label='Zip Code'
          value={localFilters.postalCode}
          onChange={postalCode => setLocalFilters({ ...localFilters, postalCode })}
        />
      ),
    },
  ]

  return (
    <>
      {filtersList.map((type: keyof SearchFilters, i: number) => (
        <div key={i}>{filterItems.find((x: { type: string }) => x.type === type)?.component}</div>
      ))}
    </>
  )
}
