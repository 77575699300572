import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { isLocal } from '../common/constants'
import carriersSlice from '../redux/carriersSlice'
import commonSlice from '../redux/commonSlice'
import contractLaneSlice from '../redux/contractLaneSlice'
import createNewLoadSlice from '../redux/createNewLoadSlice'
import externalTrackingSlice from '../redux/externalTrackingSlice'
import instantQuotesSlice from '../redux/instantQuotesSlice'
import invoiceSlice from '../redux/invoiceSlice'
import itemTemplateSlice from '../redux/itemTemplateSlice'
import loadsSlice from '../redux/loadsSlice'
import locationsSlice from '../redux/locationsSlice'
import projectsSlice from '../redux/projectsSlice'
import quotingToolSlice from '../redux/quotingToolSlice'
import reportsSlice from '../redux/reportsSlice'
import trackingSlice from '../redux/trackingSlice'
import userSlice from '../redux/userSlice'
import { rejectedThunkListenerMiddleware } from './middleware'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const appReducer = combineReducers({
  user: userSlice,
  carriers: carriersSlice,
  loads: loadsSlice,
  reports: reportsSlice,
  locations: locationsSlice,
  createNewLoad: createNewLoadSlice,
  common: commonSlice,
  tracking: trackingSlice,
  instantQuote: instantQuotesSlice,
  externalTracking: externalTrackingSlice,
  quotingTool: quotingToolSlice,
  invoices: invoiceSlice,
  itemTemplate: itemTemplateSlice,
  contractLane: contractLaneSlice,
  projects: projectsSlice,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === PURGE) {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// it's hard to type this, so we'll just use any
const persistConfig: any = {
  key: 'root',
  whitelist: ['common'],
  storage,
  version: isLocal ? 1 : Number(__BUILD_VERSION__),
  migrate: (state: RootState, currentVersion: number) => {
    if (state?._persist && state._persist.version !== currentVersion) {
      return Promise.resolve({})
    }
    return Promise.resolve(state)
  },
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configuredStore = {
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rejectedThunkListenerMiddleware),
  enhancers: (getDefaultEnhancers: any) => getDefaultEnhancers().concat(sentryReduxEnhancer),
}

export const store = configureStore(configuredStore)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
