import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import { TableOrder } from '../common/types'
import { formatDateForBackend, getOrderingString, keysToCamelCase } from '../common/utils'

type LoadsInvoice = {
  loading: {
    loads: boolean
  }
  count: {
    loads: number
  }
  loads: Array<{
    id: number
    customerReferenceId: string
    invoicedDate: Date
    invoiceAmount: number
    isReceived: boolean
    receivedAmount: number
  }>
  offset: number
  limit: number
  order: TableOrder
  filters: any
}

const initialState: LoadsInvoice = {
  loading: {
    loads: false,
  },
  count: {
    loads: 0,
  },
  loads: [],
  offset: 0,
  limit: 50,
  order: { label: '', direction: '', key: '' },
  filters: initialFilters,
}

export const getLoadsInvoice = createAsyncThunk(
  'invoice/getLoadsInvoice',
  async (_, { getState, rejectWithValue }) => {
    const {
      limit,
      offset,
      filters,
      order: { label, direction, key },
    } = (getState() as RootState).invoices

    const ordering = getOrderingString(label, direction, key, '-id')
    try {
      const response = await api.get('/customer/api/customer-invoiced-loads/', {
        params: {
          limit,
          offset,
          ordering,
          archived: false,
          id: filters.loadId,
          is_received: filters.isReceived,
          customer_reference_id__icontains: filters.refId,
          invoiced_date__gte: formatDateForBackend(filters.invoicedStartDate),
          invoiced_date__lte: formatDateForBackend(filters.invoicedEndDate),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadInvoiceURL = createAsyncThunk(
  'invoice/getLoadInvoiceURL',
  async ({ id, isReceived }: { id?: number; isReceived?: boolean }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/customer-invoice-url/${id}`, {
        params: {
          link_source: isReceived ? 'TMS' : 'QB',
        },
      })
      window.open(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoadsInvoice.pending, state => {
        state.loading.loads = true
      })
      .addCase(getLoadsInvoice.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.loads = false
        state.count.loads = count
        state.loads = results
      })
      .addCase(getLoadsInvoice.rejected, (state, { payload }) => {
        state.loading.loads = false
        toast.error(getErrorString(payload, 'Error getting loads'))
      })
      .addCase(getLoadInvoiceURL.pending, state => {
        state.loading.loads = true
      })
      .addCase(getLoadInvoiceURL.fulfilled, state => {
        state.loading.loads = false
      })
      .addCase(getLoadInvoiceURL.rejected, (state, { payload }) => {
        state.loading.loads = false
        toast.error(getErrorString(payload, 'Error getting invoice'))
      })
  },
})

export const { setLimit, setOffset, setOrder, setFilters } = invoiceSlice.actions

export default invoiceSlice.reducer
