export const colors = [
  {
    status: 'AUTHORIZED FOR Property',
    color: 'bg-light-green',
  },
  {
    status: 'AUTHORIZED FOR broker Property',
    color: 'bg-light-green',
  },
  {
    status: 'Required',
    color: 'bg-light-pink',
  },
  {
    status: 'no bid',
    color: 'bg-pending',
  },
  {
    status: 'won and accepted',
    color: 'bg-success !text-white',
  },
  {
    status: 'won',
    color: 'bg-success !text-white',
  },
  {
    status: 'W9 Form',
    color: 'bg-status-light-blue',
  },
  {
    status: 'Insurance',
    color: 'bg-status-light-blue',
  },
  {
    status: 'Initiated',
    color: 'bg-status-light-blue',
  },
  {
    status: 'MC Certificate',
    color: 'bg-status-light-blue',
  },
  {
    status: 'pending',
    color: 'bg-status-light-blue',
  },
  {
    status: 'in transit',
    color: 'bg-in-transit',
  },
  {
    status: 'en route',
    color: 'bg-in-transit',
  },
  {
    status: 'printed',
    color: 'bg-accepted',
  },
  {
    status: 'mailed',
    color: 'bg-accepted',
  },
  {
    status: 'delayed',
    color: 'bg-delayed',
  },
  {
    status: 'booked',
    color: 'bg-booked',
  },
  {
    status: 'quoted',
    color: 'bg-quoted',
  },
  {
    status: 'accepted',
    color: 'bg-success !text-white',
  },
  {
    status: 'carrier active',
    color: 'bg-success !text-white',
  },
  {
    status: 'assigned',
    color: 'bg-assigned',
  },
  {
    status: 'picked',
    color: 'bg-picked',
  },
  {
    status: 'pickup on track',
    color: 'bg-pickup-on-track',
  },
  {
    status: 'pickup off track',
    color: 'bg-fail',
  },
  {
    status: 'delivery on track',
    color: 'bg-delivery-on-track',
  },
  {
    status: 'delivery off track',
    color: 'bg-fail',
  },
  {
    status: 'paid',
    color: 'bg-success',
  },
  {
    status: 'posted',
    color: 'bg-success',
  },
  {
    status: 'success',
    color: 'bg-success',
  },
  {
    status: 'archived',
    color: 'bg-fail',
  },
  {
    status: 'denied',
    color: 'bg-fail',
  },
  {
    status: 'declined',
    color: 'bg-fail',
  },
  {
    status: 'no',
    color: 'bg-fail',
  },
  {
    status: 'unpaid',
    color: 'bg-fail',
  },
  {
    status: 'inactive',
    color: 'bg-fail',
  },
  {
    status: 'void',
    color: 'bg-fail',
  },
  {
    status: 'rejected',
    color: 'bg-fail',
  },
  {
    status: 'deleted',
    color: 'bg-fail',
  },
  {
    status: 'error',
    color: 'bg-fail',
  },
  {
    status: 'expired',
    color: 'bg-fail',
  },
  {
    status: 'failed to delete',
    color: 'bg-fail',
  },
  {
    status: 'failed to post',
    color: 'bg-fail',
  },
  {
    status: 'active',
    color: 'bg-success !text-white',
  },
  {
    status: 'delivered',
    color: 'bg-delivered',
  },
  {
    status: 'yes',
    color: 'bg-success',
  },
  {
    status: 'on time',
    color: 'bg-on-time',
  },
  {
    status: 'draft',
    color: 'bg-pending',
  },
  {
    status: 'available',
    color: 'bg-available',
  },
  {
    status: 'plan',
    color: 'bg-plan',
  },
  {
    status: 'unloading',
    color: 'bg-unloading',
  },
  {
    status: 'loading',
    color: 'bg-loading',
  },
  {
    status: 'approved',
    color: 'bg-success',
  },
  {
    status: 'failed',
    color: 'bg-fail',
  },
  {
    status: 'bookable',
    color: 'bg-available',
  },
  {
    status: 'shipper',
    color: 'bg-in-transit',
  },
  {
    status: 'consignee',
    color: 'bg-light-pink',
  },
  {
    status: 'origin',
    color: 'bg-in-transit',
  },
  {
    status: 'destination',
    color: 'bg-light-pink',
  },
  {
    status: 'carrier',
    color: 'bg-quoted',
  },
  {
    status: 'start',
    color: 'bg-in-transit',
  },
  {
    status: 'end',
    color: 'bg-light-pink',
  },
  {
    status: 'confirmed',
    color: 'bg-confirmed',
  },
  {
    status: 'claim',
    color: 'bg-claim',
  },
  {
    status: 'ready to bill',
    color: 'bg-ready-to-bill',
  },
  {
    status: 'needs appt',
    color: 'bg-needs-appt',
  },
  {
    status: 'customer',
    color: 'bg-assigned',
  },
  {
    status: 'released',
    color: 'bg-loading',
  },
  {
    status: 'release requested',
    color: 'bg-pending',
  },
  {
    status: 'in process',
    color: 'bg-pending',
  },
  {
    status: 'completed',
    color: 'bg-success !text-white',
  },
  {
    status: 'refunded',
    color: 'bg-success !text-white',
  },
  {
    status: 'billing in progress',
    color: 'bg-billing-in-progress',
  },
  {
    status: 'carrier invited',
    color: 'bg-exo-orange',
  },
  {
    status: 'not found',
    color: 'bg-fail',
  },
  {
    status: 'selected',
    color: 'bg-brand-accent',
  },
  {
    status: 'Weak',
    color: 'bg-fail',
  },
  {
    status: 'Moderate',
    color: 'bg-brand-accent',
  },
  {
    status: 'Strong',
    color: 'bg-light-green',
  },
  {
    status: 'Very Strong',
    color: 'bg-success !text-white',
  },
  {
    status: 'Appt Requested',
    color: 'bg-pink',
  },
  {
    status: 'Material Not Ready',
    color: 'bg-carrot',
  },
  {
    status: 'Not Posted',
    color: 'bg-fail',
  },
  {
    status: 'Tendered',
    color: 'bg-tendered',
  },
  {
    status: 'Tender Requested',
    color: 'bg-tendered',
  },
  {
    status: 'Tender Rejected',
    color: 'bg-tender-rejected',
  },
  {
    status: 'Tender Accepted',
    color: 'bg-tender-accepted',
  },
  {
    status: 'On Track',
    color: 'bg-on-track !text-white',
  },
  {
    status: 'Off Track',
    color: 'bg-off-track !text-white',
  },
  {
    status: 'Cancelled',
    color: 'bg-light-gray',
  },
  {
    status: 'Awaiting Carrier',
    color: 'bg-brand-accent',
  },
]

export const statusColorMap = new Map<string, string>(
  colors.map(item => [item.status.toLowerCase().replace(/[_-]/g, ' '), item.color]),
)
